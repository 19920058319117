import React, { useState,useContext ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
interface ModalProps {
  show: boolean;
  onHide: () => void;
  setData: any;
  isEditing: boolean;
  editContact: any | null;
  fetchData: () => void;
}

const CustomModal: React.FC<ModalProps> = ({ show, onHide, setData, isEditing, editContact, fetchData }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [token] = useContext(UserContext);
  const {t} = useTranslation();

  useEffect(() => {
    if (isEditing && editContact) {
      setName(editContact.name);
      setPhone(editContact.phone);
      setCompany(editContact.company);
      setTitle(editContact.title);
      setEmail(editContact.email);
      setNotes(editContact.notes);
    } else {
      resetForm();
    }
  }, [isEditing, editContact]);

  const resetForm = () => {
    setName('');
    setPhone('');
    setCompany('');
    setTitle('');
    setEmail('');
    setNotes('');
    setNameError("");
    setEmailError("");
    setPhoneError("");
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    let isValid = true;

    if (!name) {
      setNameError(t("Please enter a name"));
      isValid = false;
    } else {
      setNameError("");
    }

    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(t("Invalid Email Format"));
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!phone) {
      setPhoneError(t("Please enter a contact number"));
      isValid = false;
    } else if (!/^[0-9#*+]*$/.test(phone)) {
      setPhoneError(t("Please enter a valid contact number"));
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (isValid) {
      handleSave();
    } 
  };

  const handleSave = async () => {
    try {
      const contactData = { name, phone, company, title, email, notes };

      if (isEditing && editContact) {
        await axios.post(`/api/user/contacts/edit/${editContact.id}`, contactData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          }
        });
        setData((prevData: any) => prevData.map((contact: any) => (contact.id === editContact.id ? { ...contact, ...contactData } : contact)));
        toast.success(t("Contact updated successfully"));
      } else {
        await axios.post("/api/user/contacts/add", contactData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          }
        });
        setData((prevData: any) => [...prevData, { id: Math.random().toString(), ...contactData }]);
        fetchData();
        toast.success(t("Contact added successfully"));
      }

      onHide();
    } catch (error:any) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(t("Contact number already exists"));
        } else {
          toast.error(t("There was an error saving the contact"));
        }
      } else {
        toast.error(t("There was an error saving the contact"));
      }
      
    }
  };

  const handleClose = () => {
    resetForm();
    onHide();
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing && editContact ? t("Edit Contact") : t("Add Contact")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicName">
              <Form.Label>{t("Contact Name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Enter Contact Name")} 
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
                required
                isInvalid={!!nameError}
              />
              <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t("Email Address")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("Enter email")}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                isInvalid={!!emailError}
              />
              <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicPhone">
              <Form.Label>{t("Contact Number")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Enter Contact Number")}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneError("");
                }}
                isInvalid={!!phoneError}
              />
              <Form.Control.Feedback type="invalid">{phoneError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control type="text" placeholder={t("Enter Title")} value={title} onChange={(e) => setTitle(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formBasicCompany">
              <Form.Label>{t("Company Name")}</Form.Label>
              <Form.Control type="text" placeholder={t("Enter Company Name")} value={company} onChange={(e) => setCompany(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formBasicNotes">
              <Form.Label>{t("Notes")}</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder={t("Enter notes")} value={notes} onChange={(e) => setNotes(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing && editContact ? t("Update Contact") : t("Save Contact")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
