import React, { useContext, useEffect, useRef, useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import './kiosk.css';
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const [token, setToken] = useContext(UserContext);
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const response = await axios.get("/api/users/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: (token || ""),
          }
        });
        let company_name = response.data.company_name;
        setCompanyName(company_name);
      } catch (error) {
        setToken("");
        Cookies.remove('Token');
        navigate("/");
      }
    };
    fetchUser();
  }, []);
  
  const handleLogOut = () => {
    setToken("");
    Cookies.remove('Token');
    navigate("/");
  };
  
  return (
    <div className="kiosk-navbar" >
      <ul className="nav-ul">
         <div className="company-name">{companyName}</div>
        <li className="nav-li"><a href="#" onClick={handleLogOut} ><LogoutIcon/></a></li>
      </ul>
    </div>
  );
}

export default Navbar;
