import React from "react";
import {useTranslation} from "react-i18next";

interface IncomingDialerProps {
  session: any;
  answerCall: () => void;
  hangupCall: () => void;
  answering: boolean;
  
}

const IncomingDialer: React.FC<IncomingDialerProps> = ({
  session,
  answerCall,
  answering,
  hangupCall,
}) => {
  const {t} = useTranslation();
  return (
      <>
      {!answering && 
        (
        <div className="sidebar-mr">
        <div className="call-notification-container">
          <div className="call-notification-text green-clr">
            <div>{t("Incoming Call")}</div>
            {/* <div> {session.remote_identity.display_name}</div> */}
            <div> {session.remote_identity.uri.user}</div>
          </div>

          <div className="">
            <img
              className="img-circle"
              src="./assets/user-placeholder.jpg"
              alt="user"
            />
          </div>
          <div className="call-notification-container">
            <button
              type="button"
              className="btn btn-success button-call"
              onClick={answerCall}
            >
              {t("Answer")}
            </button>
            <button
              type="button"
              className="btn btn-danger ms-2 button-call"
              onClick={hangupCall}
            >
              {t("End")}
            </button>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default IncomingDialer;
