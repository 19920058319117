import axios from 'axios';
import React, { useContext, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { UserContext } from '../../../context/UserContext';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaDownload } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';
import {Tooltip} from '@mui/material';
import { useTranslation } from 'react-i18next';
import './AudioPlayer.css';

interface AudioPlayerProps {
    recordingUrl: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ recordingUrl }) => {
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [playing, setPlaying] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(1);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);
    const [played, setPlayed] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [token] = useContext(UserContext);
    const playerRef = useRef<ReactPlayer>(null);
    const {t} = useTranslation();
    const handlePlayPause = async () => {
        if (!audioUrl) {
            setLoading(true);
            try {
                const response = await axios.get(`/api/user/recording/playback/${recordingUrl}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token || "",
                    },
                    responseType: 'blob',
                });
                const url = URL.createObjectURL(response.data);
                setAudioUrl(url);
                setLoading(false);
                setPlaying(true);
            } catch (error) {
                console.log("Error fetching data:", error);
                setLoading(false);
            }
        } else {
            setPlaying(!playing);
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (newVolume === 0) {
            setIsMuted(true);
        } else {
            setIsMuted(false);
        }
    };

    const handleSeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);
        setPlayed(newValue);
        if (playerRef.current) {
            playerRef.current.seekTo(newValue, 'fraction');
        }
    };

    const handleProgress = (state: { played: number }) => {
        setPlayed(state.played);
    };

    const handleDuration = (duration: number) => {
        setDuration(duration);
    };

    const handleDownload = async () => {
        setDownloadLoading(true);
        try {
            const response = await axios.get(`/api/user/recording/playback/${recordingUrl}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token || "",
                },
                responseType: 'blob',
            });
            const url = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `recording-${recordingUrl}`;
            setDownloadLoading(false);
            link.click();
        } catch (error) {
            console.log("Error fetching data:", error);
        }
            
        
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className="audio-player-container">
            <div className="custom-controls">
            <Tooltip title={loading ? "" : (playing ? t("Pause") : t("Play"))}>
    <span>
        <button onClick={handlePlayPause} disabled={loading}>
            {loading ? (
                <Spinner animation="border" variant="dark" size="sm" />
            ) : (
                playing ? <FaPause /> : <FaPlay />
            )}
        </button>
    </span>
</Tooltip>
                <div className="time-display">
                    <span>{formatTime(played * duration)}</span> / <span>{formatTime(duration)}</span>
                </div>
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={played}
                    onChange={handleSeekChange}
                    aria-label="Seek control"
                    disabled={loading}
                />
                <div
                    className={`volume-container ${showVolumeSlider ? 'expanded' : ''}`}
                    onMouseEnter={() => setShowVolumeSlider(true)}
                    onMouseLeave={() => setShowVolumeSlider(false)}
                >
                    <button onClick={toggleMute}>
                        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                    </button>
                    <span>
                    {showVolumeSlider && (
                        <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={isMuted ? 0 : volume}
                            onChange={handleVolumeChange}
                            aria-label="Volume control"
                            className="volume-slider"
                        />
                    )}
                    </span>
                </div>
                <Tooltip title={t("Download")}>
                <button className="download-button" onClick={handleDownload} disabled={downloadLoading}>
                    { downloadLoading ? <Spinner  animation="grow" variant="dark" size="sm" /> : <FaDownload />}
                </button>
                </Tooltip>
            </div>
            {audioUrl && (
                <ReactPlayer
                    ref={playerRef}
                    url={audioUrl}
                    playing={playing}
                    controls={false}
                    volume={isMuted ? 0 : volume}
                    onPause={() => setPlaying(false)}
                    onPlay={() => setPlaying(true)}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    width="0"
                    height="0"
                />
            )}
        </div>
    );
};

export default AudioPlayer;
