import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface CallTransferModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  transferCall: (value:string) => void;

}

const CallTransferModal: React.FC<CallTransferModalProps> = ({ showModal,setShowModal, transferCall }) => {
  const { t } = useTranslation();
  const [extension, setExtension] = useState("");

  const handleCallTranfer = () => {
    transferCall(extension);
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}  centered animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Transfer Call")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formExtension">
            <Form.Label>{t("Enter Extension")}</Form.Label>
            <Form.Control 
                type="text" 
                placeholder={t("Extension")} 
                value ={extension}
                onChange={(e) => setExtension(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={() => handleCallTranfer() } disabled={!extension.trim()}>
          {t("Transfer")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallTransferModal;
