import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import Cookies from "js-cookie";
import "./css/main.css";
import "./css/util.css";
import logo from "./images/logo.svg";
import welcome from "./images/welcome.png";
import bgimg from "./images/bgimg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faHouse, faXmark} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
// import JsSIP from "jssip";
// JsSIP.debug.enable("JsSIP:*");

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [companyCode, setCompanyCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useContext(UserContext);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const dashboard_interface = async (access_token: string) => {
    try {
      await axios.get("/api/user/dashboard", {
        headers: {
          "Content-Type": "application/json",
          Authorization: access_token || "",
        },
      });

      navigate("/Kiosk");
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          navigate("/Dashboard");
        } else if (
          error.response.data &&
          error.response.data.error_description
        ) {
          setErrorMessage(error.response.data.error_description);
        } else {
          setErrorMessage("Login failed.");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  const submitLogin = async () => {
    setLoading(true);
    try {
      const requestData = {
        company_code: companyCode,
        username: username,
        password: password,
      };
      const url = "/api/token";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: requestData,
        url,
      };
      const response = await axios(requestOptions);
      
      const data = await response.data;
      if (!data.access_token) {
        setErrorMessage(data.detail);
        navigate("/");
      } else {
        setToken(data.access_token);
        Cookies.set("Token", token || "", { expires: 1 });
        await dashboard_interface(data.access_token);
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Something went wrong. Please contact admin.");
      }
    }
    finally{
      setLoading(false);
    }
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    submitLogin();
  };

  return (
    <div className="login">
      <div className="login-content">
        <div className="image-container">
          <img src={bgimg} alt="Frame" className="login-image"></img>
          <div className="text-overlay">
            <p className="login-title">
              Revolutionize Your Call Center with AI{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="login-form">
        <div className="login-content1">
          <div className="ml70">
          <a href="/home"> <img src={logo} alt="Logo" className="login-logo"></img></a> 
          </div>
          <div className="ml70">
            <img src={welcome} alt="Welcome" className="login-welcome"></img>
          </div>
          <div className="ml70 softphone-login">Softphone Login</div>
          <form
            className="form-new-class ml70 validate-form"
            onSubmit={handleLogin}
          >
            <div data-validate="Valid email is required: ex@abc.xyz">
              <p className="input-label">Company Code</p>
              <input
                className="input100"
                type="text"
                name="companyCode"
                placeholder="Company Code"
                id="companyCode"
                autoComplete="off"
                value={companyCode}
                onChange={(e) => setCompanyCode(e.target.value)}
                maxLength={5}
                required
              />
            </div>
            <div data-validate="Valid email is required: ex@abc.xyz">
              <p className="input-label">Username</p>
              <input
                className="input100"
                type="text"
                name="username"
                placeholder="Username"
                id="username"
                autoComplete="off"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                maxLength={100}
                required
              />
            </div>
            <div data-validate="Password is required">
              <p className="input-label">Password</p>
              <div className="password-container">
                <input
                  className="input100"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  autoComplete="off"
                  value={password}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={250}
                  required
                />
                <button
                  type="button"
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                  size="xs"
                    icon={passwordVisible ? faEyeSlash : faEye}
                  />
                </button>
              </div>
            </div>
            {errorMessage && (
              <p className="error-msg" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
            <button type="submit" className="login-submit-btn">
            {loading ? (
                      <div className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>Login</>
                    )}
                  </button>
            <p className="txt2">
              To go to admin login
              <a className="login-a" href="/admin/login">
                Click Here
              </a>
            </p>
            <p className="txt2">
           
              <a className="go-to-home" href="/home">
              Go to <FontAwesomeIcon icon={faHouse} />
              
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  
   
  );
};

export default Login;
