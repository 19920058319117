import React, { useState,useContext ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
interface ModalProps {
  show: boolean;
  onHide: () => void;
  uniqueId: string;
  modalState: string;
}

const CallHistoryModal: React.FC<ModalProps> = ({ show, onHide,uniqueId,modalState}) => {
  
  const [token] = useContext(UserContext);
  const [transcriptionData, setTranscriptionData] = useState<string>("");
  const [transcriptSummaryData, setTranscriptSummaryData] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); 
  const {t}= useTranslation();
  const fetchTranscriptionData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/user/call/transcription/view/${uniqueId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: (token || ""),
        }
      });
      const transcriptData = response.data.transcript;
      setTranscriptionData(transcriptData);
      return true;
    } catch (error) {
      console.log("Error fetching transcription data:", error);
    } finally {
      setLoading(false);
    }
  }
 
  const fetchTranscriptSummaryData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/user/summary/transcription/view/${uniqueId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: (token || ""),
        }
      });
      const transcriptSummaryData = response.data.data;
      setTranscriptSummaryData(transcriptSummaryData);
      return true;
    } catch (error) {
      console.log("Error fetching transcription data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(show && modalState === 'transcript') {
      fetchTranscriptionData();
    }
    if(show && modalState === 'summary') {
      fetchTranscriptSummaryData();
    }
  }, [show,modalState]);


  return (
    <Modal show={show} onHide={onHide} backdrop="static" animation={true}>
      <Modal.Header closeButton>
        {modalState === 'transcript' && <Modal.Title>{t("Transcription")}</Modal.Title>}
        {modalState === 'summary' && <Modal.Title>{t("Summary")}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">{t("Loading")}...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {modalState === 'transcript' &&
              <Form.Control
                as="textarea"
                rows={3}
                style={{ width: '450px', height: '300px', overflowY: 'scroll' }}
                value={transcriptionData}
                readOnly
              />
            }
            {modalState === 'summary' &&
              <Form.Control
                as="textarea"
                rows={3}
                style={{ width: '450px', height: '300px', overflowY: 'scroll' }}
                value={transcriptSummaryData}
                readOnly
              />
            }
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
        {t("Close")} 
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};

export default CallHistoryModal;
