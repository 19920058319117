import React, { useState, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface WorkspaceProps {
  contactDetail: any;
  setContactDetail: any;
  phoneNumber: any;
  setPhoneNumber: any;
  name: string;
  setName: any;
  phone: any;
  setPhone: any;
  email: string;
  setEmail: any;
  title: string;
  setTitle: any;
  company: string;
  setCompany: any;
  notes: string;
  setNotes: any;
  existingContact: boolean;
  setExistingContact: any;
  id: any;
  incomingCallSession: boolean;
  contactAdded: any;
  setContactAdded: any;
}
const Workspace: React.FC<WorkspaceProps> = ({
  contactDetail,
  setContactDetail,
  phoneNumber,
  setPhoneNumber,
  name,
  setName,
  phone,
  setPhone,
  email,
  setEmail,
  company,
  setCompany,
  title,
  setTitle,
  notes,
  setNotes,
  existingContact,
  setExistingContact,
  id,
  incomingCallSession,
  contactAdded,
  setContactAdded
}) => {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [saveContact, setSaveContact] = useState(false);
  const [token] = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (incomingCallSession) {
      setNameError("");
      setPhoneError("");
      setEmailError("");
    }
  }, [incomingCallSession]);

  useEffect(() => {
    if (nameError!=="" || emailError!=="" || phoneError!=="") {
      setNameError(t(nameError));
      setPhoneError(t(phoneError));
      setEmailError(t(emailError));
    }
  }, [t]);   //language translation of validation messages once any validation is on. 
 

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let isValid = true;

    if (!name) {
      setNameError(t("Please enter a name"));
      isValid = false;
    } else if (/[^a-zA-Z0-9\s]/.test(name)) {
      setNameError(t("Name should not contain special characters"));
      isValid = false;
    } else {
      setNameError("");
    }

    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(t("Invalid Email Format"));
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!phoneNumber &&!phone) {
      setPhoneError(t("Please enter a contact number"));
      isValid = false;
    }
   else if (!/^[0-9#*+]*$/.test(phoneNumber)) {
      setPhoneError(t("Please enter a valid contact number"));
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (isValid) {
      handleSave();
    }
  };

  const handleSave = async () => {
    try {
      const contactData = {
        name,
        phone: phone || phoneNumber,
        company,
        title,
        email,
        notes,
      };
      // console.log("contactDatanew", contactData);
      if (existingContact) {
        await axios.post(`/api/user/contacts/edit/${id}`, contactData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        });
        setContactDetail((prevData: any) => 
        Array.isArray(prevData) ? prevData.map((contact) => 
          contact.id === id ? { ...contact, ...contactData } : contact
        ) : []
      );
        toast.success(t("Contact updated successfully"));
        setExistingContact(false);
      } else {
        await axios.post("/api/user/contacts/add", contactData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        });
        setContactDetail((prevData: any) =>  [ ...prevData,
              { id: Math.random().toString(), ...contactData }
            ]);
        toast.success(t("Contact added successfully"));
      }
    } catch (error:any) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warning(t("Contact number already exists"));
        } else {
          toast.error(t("There was an error saving the contact"));
        }
      } else {
        toast.error(t("There was an error saving the contact"));
      }
    }
    setContactAdded(contactDetail);
    setSaveContact(true);
    setName("");
    setPhone("");
    setPhoneNumber("");
    setCompany("");
    setTitle("");
    setEmail("");
    setNotes("");
  };

  return (
    <>
      <div className="workspace-container">
        <Form>
          <h4 className="workspace-heading">
            {existingContact? t("Contact Details") : t("Add Contact")}
          </h4>
          <div className="workspace-form">
            <Form.Group controlId="formBasicName">
            <Form.Label className="workspace-label">
                {t("Contact Name")} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Enter Contact Name")}
                className={`custom-style ${name ? 'custom-bold-text' : ''}`}
                value={name ? name : ""}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
                required
                isInvalid={!!nameError}
              />
              <Form.Control.Feedback type="invalid">
                {nameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="workspace-label">
                {t("Email Address")}
              </Form.Label>
              <Form.Control
                className="custom-style"
                type="email"
                placeholder={t("Enter email")}
                value={email ? email : ""}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                isInvalid={!!emailError}
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicPhone">
            <Form.Label className="workspace-label">
                {t("Contact Number")} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                className="custom-style"
                placeholder={t("Enter Contact Number")}
                value={phone || phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setPhoneError("");
                }}
                isInvalid={!!phoneError}
                disabled={incomingCallSession && !saveContact}
              />
              <Form.Control.Feedback type="invalid">
                {phoneError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label className="workspace-label">{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                className="custom-style"
                placeholder={t("Enter Title")}
                value={title ? title : ""}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicCompany">
              <Form.Label className="workspace-label">
                {t("Company Name")}
              </Form.Label>
              <Form.Control
                type="text"
                className="custom-style"
                placeholder={t("Enter Company Name")}
                value={company ? company : ""}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicNotes">
              <Form.Label className="workspace-label">{t("Notes")}</Form.Label>
              <Form.Control
                as="textarea"
                className="custom-style"
                rows={3}
                placeholder={t("Enter notes")}
                value={notes ? notes : ""}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="formBasicTranscription">
              <Form.Label className='workspace-label'>{t("Previous Transcription")}</Form.Label>
              <Form.Control as="textarea" className="custom-style" rows={3} 
            //    value={notes} onChange={(e) => setNotes(e.target.value)}
               />
            </Form.Group> */}
          </div>
        </Form>
    
        <button
          className="workspace-btn btn btn-primary"
          onClick={handleSubmit}
        >
          {t("Save Contact")}
        </button>
      </div>
    </>
  );
};

export default Workspace;
