import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import JsSIP from "jssip";
import axios from "axios";
// import "../login/css/util.css";
import logo from "../login/images/logo.svg";
import bgimg from "../login/images/bgimg.png";
import "./userregister.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faHouse, faFile } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Alert, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import arrownew from "./image/arrownew.png";

JsSIP.debug.enable("JsSIP:*");

const UserRegistration: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyNameError, setCompanyNameError] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [hostName, setHostName] = useState<string>("");
  const [hostNameError, setHostNameError] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [clientIdError, setClientIdError] = useState<string>("");
  const [clientSecret, setClientSecret] = useState<string>("");
  const [clientSecretError, setClientSecretError] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useContext(UserContext);
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  const steps = ["Info", "Plugin", "API Details"];

  const successNotify = (message: string) => toast.success(message);
  const errorNotify = (message: string) => toast.error(message);

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCompanyName(value);
    const isNameValid = value.length <= 1;
    if (isNameValid) setCompanyNameError("Enter Valid Company Name");
    else setCompanyNameError("");
  };
  const validCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
    if (companyName.length >= 1) setCompanyNameError("");
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
    const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value);
    setPasswordError(
      isValidPassword
        ? ""
        : "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number"
    );
  };
  const validatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password))
      setPasswordError("");
  };
  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);
    const isMatch = value === password;
    setConfirmPasswordError(isMatch ? "" : "Passwords do not match");
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(isValid ? "" : "Invalid email address");
  };
  const validEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) setEmailError("");
  };
  const handleHostName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHostName(value);
    const isValidHostName =
      /^(?=.{1,253}$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/.test(
        value
      );
    setHostNameError(isValidHostName ? "" : "Invalid host name");
  };
  const validateHostName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHostName(e.target.value);
    if (
      /^(?=.{1,253}$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/.test(
        hostName
      )
    )
      setHostNameError("");
  };
  const handleClientId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setClientId(value);
    const isValidClientId = value.length > 500;
    setClientIdError(isValidClientId ? "Enter Valid Client Id" : "");
  };
  const validateClientId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientId(e.target.value);
    if (clientId.length < 500) setClientIdError("");
  };
  const handleClientSecret = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setClientSecret(value);
    const isValidClientSecret = value.length > 500;
    setClientSecretError(isValidClientSecret ? "Enter Valid Client Id" : "");
  };
  const validateClientSecret = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientSecret(e.target.value);
    if (clientSecret.length < 500) setClientSecretError("");
  };
  const handleContinue = () => {
      let isValid = true;

    // Validate email
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid Email Format");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate password
    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number"
      );
      isValid = false;
    } else {
      setPasswordError("");
    }

    // Validate confirm password
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    // Validate company name
    if (!companyName) {
      setCompanyNameError("Company Name is required");
      isValid = false;
    } else if (companyName.length == 1) {
      setCompanyNameError("Enter ValidCompany Name");
    } else {
      setCompanyNameError("");
    }

    // If all fields are valid, proceed to the next step
    if (isValid) {
      setStep(step + 1);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let isValid = true;

    // Validate email
    if (!hostName) {
      setHostNameError("Hostname is required");
      isValid = false;
    } else if (
      !/^(?=.{1,253}$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/.test(
        hostName
      )
    ) {
      setHostNameError("Invalid hostname");
      isValid = false;
    } else {
      setHostNameError("");
    }

    // Validate password
    if (!clientId) {
      setClientIdError("ClientId is required");
      isValid = false;
    } else if (clientId.length > 500) {
      setClientIdError("Enter Valid Client Id");
      isValid = false;
    } else {
      setClientIdError("");
    }

    // Validate company name
    if (!clientSecret) {
      setClientSecretError("Client Secret is required");
      isValid = false;
    } else if (clientSecret.length > 500) {
      setClientSecretError("Enter Valid Client Id");
      isValid = false;
    } else {
      setClientSecretError("");
    }

    if (isValid) {
      submitreg();
    }
  };
  const submitreg = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const requestData = {
        company_name: companyName,
        company_email: email,
        company_password: password,
        freepbx_hostname: hostName,
        freepbx_api_client_id: clientId,
        freepbx_api_client_secret: clientSecret,
      };
      const url = "/api/admin/register";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: requestData,
        url,
      };
      const response = await axios(requestOptions);
      const data = await response.data;
      if (response.status === 200) {
        setLoading(false);
        successNotify("Registration successful! Redirecting to login...");
        setTimeout(() => {
          window.location.href = "/admin/login";
        }, 3000); // 3 seconds timer
      } else {
        errorNotify("Registration failed.Server Error");
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          const errorData = error.response.data;
          const errorMessage =
            errorData.message || "Invalid registration data.";
          errorNotify(errorMessage);
        } else {
          errorNotify("Registration failed.Server Error");
        }
      } else {
        errorNotify("Registration failed.Server Error");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadClick = async () => {
    try {
      const response = await fetch("/pbx/plugin");

      if (!response.ok) {
        throw new Error("Failed to download plugin");
      }

      // Assuming the response is a file
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "pbxai.tar";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Handle error
    }
  };
  return (
    <div className="reg">
      <div className="reg-content">
        <div className="image-container">
          <img src={bgimg} alt="Frame" className="reg-image"></img>
          <div className="text-overlay">
            <p className="reg-title">Revolutionize Your Call Center with AI </p>
          </div>
        </div>
      </div>

      <div className="reg-form">
        <div className="reg-content1">
          <div className="ml70">
           <a href="/home"> <img src={logo} alt="Logo" className="reg-logo"></img></a>
          </div>
          <div className="ml70 softphone-reg">
            <h6>Please enter your information & proceed to the next step</h6>
            <div className="user-registration">User Registration</div>
          </div>
          <div className=" box-shadow">
            <div>
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={step}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
            {step === 0 && (
              <div>
                <div className="softphone-reg">Company Information</div>
                <div>
                  <form className="form-new-class validate-form">
                    <div data-validate="Company Name is required">
                      <p className="input-label">Name</p>
                      <input
                        className="input100"
                        type="text"
                        name="company_name"
                        placeholder="Company Name"
                        id="company_name"
                        autoComplete="off"
                        value={companyName}
                        onChange={validCompanyName}
                        onBlur={handleCompanyNameChange}
                      />
                      <span className="sub-error">{companyNameError}</span>
                    </div>
                    {/* Email */}
                    <div data-validate="Email is required">
                      <p className="input-label">Email</p>
                      <input
                        className="input100"
                        type="email"
                        name="company_email"
                        placeholder="example@email.com"
                        id="company_email"
                        autoComplete="off"
                        value={email}
                        onChange={validEmail}
                        onBlur={handleEmailChange}
                      />
                      <span className="sub-error">{emailError}</span>
                    </div>
                    {/* Password */}
                    <div data-validate="Password is required">
                      <p className="input-label">Create Password</p>
                      <input
                        className="input100"
                        type="password"
                        name="company_password"
                        placeholder="Password"
                        value={password}
                        id="company_password"
                        autoComplete="off"
                        onChange={validatePassword}
                        onBlur={handlePasswordChange}
                      />
                      <span className="sub-error">{passwordError}</span>
                    </div>
                    {/* Conform Password */}
                    <div data-validate="Password is required">
                      <p className="input-label">Confirm Password</p>
                      <input
                        className="input100"
                        type="password"
                        name="company_password"
                        placeholder="Password"
                        value={confirmPassword}
                        id="confirm_company_password"
                        onChange={handleConfirmPassword}
                      />
                      <span className="sub-error">{confirmPasswordError}</span>
                    </div>
                    <p className="txt2">
                      If registered already?
                      <a className="reg-a" href="/admin/login">
                        Login
                      </a>
                    </p>
                    <p className="txt2">
                      
                      <a className="go-to-home" href="/home">
                      Go Back to <FontAwesomeIcon icon={faHouse} />
                        
                      </a>
                    </p>
                    <div className="arrow-right-div">
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        style={{ color: "#0d4d93" }}
                        className="arrow-right-icon"
                        onClick={handleContinue}
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
            {step === 1 && (
              <div>
                <div className="softphone-reg">Download PBXAI Plugin</div>
                <h5 className="download-text">
                  Please download and install the PBXAI plugin in your FreePBX
                  Server for proper functionality (mandatory).
                </h5>
                
                <div className="download-div">
                  <img
                    src={arrownew}
                    alt="Download"
                    className="download-img"
                  ></img>
                </div>
                <div className="download-btn-div">
                  <button
                    className="download-btn"
                    onClick={handleDownloadClick}
                  >
                    {/* <FontAwesomeIcon
                      className="download-icon"
                      icon={faDownload}
                      size="xs"
                    /> */}
                    Download
                  </button>
                  <p className="download-p">
                  <a className="documentation-link" href="/pbxai/documentation" target="_blank">Click Here 
                  {/* <FontAwesomeIcon icon={faFile} /> */}
                  </a>&nbsp; 
                  and refer to Section 2.2 in the Admin Registration documentation for installation instructions.
                    

                </p>
                </div>

                <div className="step-2-continue">
                  <div>
                    <FontAwesomeIcon
                      icon={faCircleArrowLeft}
                      style={{ color: "#0d4d93" }}
                      className="arrow-right-icon"
                      onClick={() => setStep(0)}
                    />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleArrowRight}
                    style={{ color: "#0d4d93" }}
                    className="arrow-right-icon"
                    onClick={() => setStep(2)}
                  />
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <div className="softphone-reg">FreePBX API Details</div>
                <p className="download-p">
                    <a className="documentation-link" href="/pbxai/documentation" target="_blank">Click Here 
                    {/* <FontAwesomeIcon icon={faFile} /> */}
                    </a> &nbsp;
                    and refer to Section 2.3 in the Admin Registration documentation for API credentials.
                </p>
                <form
                  className="form-new-class validate-form"
                  onSubmit={handleSubmit}
                >
                  <div data-validate="Hostname is required">
                    <p className="input-label">HostName</p>
                    <input
                      className="input100"
                      type="text"
                      name="freepbx_hostname"
                      placeholder="FreePBX Hostname"
                      id="freepbx_hostname"
                      autoComplete="off"
                      value={hostName}
                      onBlur={handleHostName}
                      onChange={validateHostName}
                    />
                    <span className="sub-error">{hostNameError}</span>
                  </div>
                  <div data-validate="ClientId is required">
                    <p className="input-label">API Client ID</p>
                    <input
                      className="input100"
                      type="text"
                      name="clientId"
                      placeholder="Client ID"
                      id="clientId"
                      autoComplete="off"
                      value={clientId}
                      onBlur={handleClientId}
                      onChange={validateClientId}
                    />
                    <span className="sub-error">{clientIdError}</span>
                  </div>
                  <div data-validate="Client Secret is required">
                    <p className="input-label">API Client Secret</p>
                    <input
                      className="input100"
                      type="password"
                      name="freepbx_api_client_secret"
                      placeholder="Client Secret"
                      autoComplete="off"
                      value={clientSecret}
                      id="freepbx_api_client_secret"
                      onBlur={handleClientSecret}
                      onChange={validateClientSecret}
                    />
                    <span className="sub-error">{clientSecretError}</span>
                  </div>

                  <button
                    name="register"
                    type="submit"
                    className="reg-submit-btn"
                  >
                    {loading ? (
                      <div className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <>Register</>
                    )}
                  </button>

                  <div className="arrow-right-div">
                    <FontAwesomeIcon
                      icon={faCircleArrowLeft}
                      style={{ color: "#0d4d93" }}
                      className="arrow-right-icon"
                      onClick={() => setStep(1)}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default UserRegistration;
