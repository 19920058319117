import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Dashboard from "./components/dashboard/Dashboard";
import { UserProvider } from './context/UserContext';
import Login from "./components/login/Login";
import Kiosks from "./components/kiosk/kiosks";
import UserRegistration from './components/UserRegistration/UserRegistration';
import "./i18n";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(

    <Router>
      <UserProvider>
        <Routes>
      
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<App />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Kiosk" element={<Kiosks />} />
          <Route path="/UserRegistration" element={<UserRegistration />} />
        </Routes>
      </UserProvider>
    </Router>

);

