import React, { useState,useEffect,useContext } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import './callhistory.css';
import Dropdown from "react-bootstrap/Dropdown";
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallHistoryModal from './CallHistoryModal';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import folderempty from "./images/folderempty.png";
import {Tooltip} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AudioPlayer from '../common/custom_audio_player/AudioPlayer';

interface CallHistoryItem {
  calldate: string;
  duration: string;
  disposition:string;
  recordingfile: string;
  desc: string;
  num: string;
  uniqueid: string;
  transcript: boolean;
}
interface CallHistoryTable {
  data: CallHistoryItem[];
  fetchData: () => Promise<void>;
}

type StateOption = 'transcript' | 'summary';
const CallHistoryTable: React.FC= () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 10; 
  const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
  const [data, setData] = useState<CallHistoryItem[]>([]);
  const [token, setToken] = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false); 
  const [modalState, setModalState] = useState<StateOption>('transcript'); 
  const [uniqueId, setUniqueId] = useState<string>('');

  const {t} = useTranslation();
  const handleModalTranscription = (uniqueid: string) => {
    setUniqueId(uniqueid);
    setModalState('transcript');
    setShowModal(true);
  };
  
  const handleModalTranscriptionSummary = (uniqueid: string) => {
    setUniqueId(uniqueid);
    setModalState('summary');
    setShowModal(true);
  }
  const timePeriodChange = (value:number) => {
    setSelectedPeriod(value);
    setCurrentPage(0);
  }

  
  
  const handleRefresh=()=>{
    fetchData(selectedPeriod);
  }
  // Loader component
const LoaderRecording = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? "" : prev + "."));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "5px" }}>
      <span>{t("Loading audio")}{dots}</span>
    </div>
  );
};

  const fetchData = async (period: number) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/user/extension/history/${period}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: (token || ""),
        }
      });
      const cdr_data = response.data;
      setData(cdr_data);
      return true;
    } catch (error) {
      console.log("Error fetching data:", error);
    }finally{
      setLoading(false);
    }
  }
 const filteredData = data?.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil((filteredData?.length ?? 0) / itemsPerPage);
  const currentItems = filteredData?.slice(offset, offset + itemsPerPage);
const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    fetchData(selectedPeriod);
  }, [selectedPeriod]);
  
 return (
  <div >
   {loading?(
   <div className="loader">
    <Spinner animation="border" variant="primary"/>
   </div>
      ) : (
    <div >
      {/* <h1 className='call-history-heading'>Call History</h1> */}
      <div className='search-container'>
        <div>
          <button className='refresh-button' onClick={handleRefresh}>
        <FontAwesomeIcon
         icon={faArrowsRotate}
         className='refresh-icon'
          />
         </button>
        </div>
      <div>
    <Dropdown>
          <Dropdown.Toggle className="drop-color" id="dropdown-basic">
          {selectedPeriod === null ? 'Select Time Period' : selectedPeriod === 0 ? t("This Week") : selectedPeriod === 1 ? t("One Month") : selectedPeriod === 2 ? t("Six Months") : t("All")}
          </Dropdown.Toggle>
  
          <Dropdown.Menu className='time-periods'>
            <Dropdown.Item href="#" onClick={() => timePeriodChange(0)}>{t("This Week")}</Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => timePeriodChange(1)}>{t("One Month")}</Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => timePeriodChange(2)}>{t("Six Months")}</Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => timePeriodChange(3)}>{t("Show All")}</Dropdown.Item>
          </Dropdown.Menu>
      </Dropdown>
        </div>
        <div className="call-history-search">
              <input
                type="text"
                id='search'
                name='search'
                placeholder={t("Search By")}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
        </div>
      </div>
      <div className="container-fluid table-responsive">
      <Table bordered hover>
      <thead >
          <tr>
            <th>{t("Date Time")}</th>
            <th>{t("Description")}</th>
            <th>{t("Duration")}</th>
            <th>{t("Playback")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item, index) => (
            <tr key={index}>
              <td>{item.calldate}</td>
              <td>
                <div  className='call-direction'>
                  <div className='num-disposition'>
              {item.num}<br/>
              {item.desc === 'O' ? (
              <>
              {t("Outgoing")}
              </>
              ) : (
               <>
              {t("Incoming")}
               </>
              )}
             
              </div>
              <div>
              {item.disposition==="ANSWERED" && (<Tooltip title={t("Answered")}><PhoneInTalkIcon  style={{ color: "green",fontSize: "19px" }} /></Tooltip>)} 
              {item.disposition==="NO ANSWER" && (<Tooltip title={t("No Answer")}><PhoneMissedIcon style={{ color: "red",fontSize: "19px" }} /></Tooltip>) }
              {item.disposition==="BUSY" && (<Tooltip title={t("Busy")}><PhonePausedIcon style={{ color: "orange",fontSize: "19px" }} /></Tooltip>) }
              {item.disposition==="FAILED" && (<Tooltip title={t("Call Failed")}><PhoneDisabledIcon style={{ color: "red",fontSize: "19px" }} /></Tooltip>) }
              </div>
              </div>
             </td>
              <td>{item.duration} {'sec'}</td>
              <td  className="playback-column">
                {item.disposition !== "ANSWERED" ? (
                  "-"
                ): <AudioPlayer recordingUrl={item.recordingfile} />
               }
              </td>
                <td>
                {item.transcript  ? (
                    <div >
                    <Tooltip title={t("Transcription")}><VoiceChatIcon className='voice-chat'  style={{ cursor: "pointer" }} onClick={() => handleModalTranscription(item.uniqueid)} /></Tooltip>
                    <Tooltip title={t("Summary")}><SummarizeIcon  style={{ cursor: "pointer" }} onClick={() => handleModalTranscriptionSummary(item.uniqueid)} /></Tooltip>
                    </div>
                   
                ):("-")}
                </td>
                
            </tr>
          ))}
          {currentItems?.length === 0 && (
            <tr>
              <td className='table-no-data' colSpan={6}>
                <img src={folderempty} className='nodata-img'></img>
               <div style={{paddingBottom:"16rem",color:"#0161b1"}}>{t("No data found")}</div> 
                </td>
            </tr>
          )}
        </tbody>
      </Table>
      </div>
      
      <ReactPaginate
        previousLabel={'<<'}
        nextLabel={'>>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={pageCount === 1 ? 'd-none' : 'pagination'}
        activeClassName={'active'}
        previousClassName={currentPage === 0 ? 'd-none' : 'page-item'}
        nextClassName={currentPage === pageCount - 1 ? 'd-none' : 'page-item'}
      />
    </div>
     )}
    <CallHistoryModal show={showModal} onHide={() => setShowModal(false)}  uniqueId={uniqueId} modalState={modalState} />
    </div>
  );
};

export default CallHistoryTable;

