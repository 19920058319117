import React, { useState,useContext ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import {Tooltip} from '@mui/material';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReactPaginate from 'react-paginate';
import folderempty from '../callhistory/images/folderempty.png';
import { Form, Spinner } from 'react-bootstrap';
import CallHistoryModal from '../callhistory/CallHistoryModal'; 
import Dropdown from "react-bootstrap/Dropdown";
import AudioPlayer from '../common/custom_audio_player/AudioPlayer';
interface ModalProps {
    show: boolean;
    onHide: () => void;
    setData: any;
    isEditing: boolean;
    fetchData: () => void;
    contactNumber:string;
    viewModalState: string;
    notes:string;
}
  interface CallHistoryItem {
    calldate: string;
    desc: string;
    disposition: string;
    duration: string;
    num: string;
    recordingfile: string;
    transcript: boolean;
    uniqueid: string;
}
type StateOption = 'transcript' | 'summary';
const ViewModal: React.FC<ModalProps> = ({ show,onHide,contactNumber,viewModalState,notes}) => {
    const [currentRecordingFile, setCurrentRecordingFile] = useState<string>('');
    const [loadingRecording, setLoadingRecording] = useState<boolean>(false);
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const [token, setToken] = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [callHistoryData, setCallHistoryData] = useState<CallHistoryItem[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [uniqueId, setUniqueId] = useState<string>('');
    const [modalState, setModalState] = useState<StateOption>('transcript'); 
    const [showModal, setShowModal] = useState<boolean>(false); 
    const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
    const itemsPerPage = 8; 
    const {t} = useTranslation();

    const handleClose = () => {
        setCurrentRecordingFile('');
        setAudioUrl(null);
        setLoadingRecording(false);
        onHide();
        setSelectedPeriod(0);
      }
      const handleModalTranscription = (uniqueid: string) => {
        setUniqueId(uniqueid);
        setModalState('transcript');
        setShowModal(true);
      };
      const handleModalTranscriptionSummary = (uniqueid: string) => {
        setUniqueId(uniqueid);
        setModalState('summary');
        setShowModal(true);
      }
      const timePeriodChange = (value:number) => {
        setSelectedPeriod(value);
        setCurrentPage(0);
      }
    
      const handlePageChange = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected);
      };
      const viewHistory = async () => {
        setLoading(true);
        const url = "/api/user/extension/history";
        const payload = {
          duration:selectedPeriod,
          contact_number: contactNumber,
        };
    
        try {
          
          const res = await axios.post(url, payload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: token || "",
            },
          });
          const callHistoryData = res.data;
          setCallHistoryData(callHistoryData);
        //  console.log(callHistoryData.calldate,"callHistoryData");
        } catch (error) {
          console.error("Error:", error);
        }
        finally{
          setLoading(false);
        }
      };
      useEffect(() => {
        if(show && viewModalState=== 'history' ) {
          viewHistory();
        }
        
      },[show,selectedPeriod]);
    //   }
      const handleCallPlayback = async (recordingfile: string) => {
        setCurrentRecordingFile(recordingfile);
        setLoadingRecording(true); 
        try {
          const response = await axios.get(`/api/user/recording/playback/${recordingfile}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: (token || ""),
            },
            responseType: 'blob',
          });
          const url = URL.createObjectURL(response.data);
          setAudioUrl(url);
          setLoadingRecording(false);
        } catch (error) {
          console.log("Error fetching data:", error);
        }finally{
          setLoading(false);
        }
      }
      const LoaderRecording = () => {
        const [dots, setDots] = useState("");
      
        useEffect(() => {
          const interval = setInterval(() => {
            setDots((prev) => (prev.length >= 3 ? "" : prev + "."));
          }, 500);
      
          return () => clearInterval(interval);
        }, []);
      
        return (
          <div style={{ textAlign: "center", marginTop: "5px" }}>
            <span>{t("Loading audio")}{dots}</span>
          </div>
        );
      };
      const offset = currentPage * itemsPerPage;
      const currentItems = callHistoryData.slice(offset, offset + itemsPerPage);
      const pageCount = Math.ceil(callHistoryData.length / itemsPerPage);
return (
    <>
     
      <Modal
       show={show} onHide={handleClose}
       size={viewModalState === 'notes' ? undefined : 'xl'}
       aria-labelledby="contained-modal-title-vcenter"
       centered
        backdrop="static"
        animation={true}
        dialogClassName="custom-modal"
        className={showModal ? 'd-none' : ''}
       >
        <Modal.Header closeButton>
        {viewModalState === 'history' && <Modal.Title className="modal-title-centered">{t("Contact History")}</Modal.Title>}
        {viewModalState === 'notes' && <Modal.Title className="modal-title-centered">{t("Notes")}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          <div>
          {loading?(
   <div className="modal-loader">

    <Spinner animation="border" variant="primary"/>
   </div>
      ) : (
        <>
        {viewModalState === 'history' ? (
          currentItems?.length === 0 ? (
            <div className='no-data-container'>
              <img className='no-data-image' src={folderempty} />
              <div style={{ paddingBottom: "0.2rem", color: "#0161b1" }}>{t("No data found")}</div>
            </div>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>{t("Date Time")}</th>
                  <th>{t("Description")}</th>
                  <th>{t("Duration")}</th>
                  <th>{t("Playback")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.calldate}</td>
                    <td>
                    <div  className='call-direction'>
                  <div className='num-disposition-modal'>
              {item.num}<br/>
              {item.desc === 'O' ? (
              <>
              {t("Outgoing")}
              </>
              ) : (
               <>
              {t("Incoming")}
               </>
              )}
             
              </div>
              <div>
              {item.disposition==="ANSWERED" && (<Tooltip title={t("Answered")}><PhoneInTalkIcon className='disposition-icon' style={{ color: "green",fontSize: "19px" }} /></Tooltip>)} 
              {item.disposition==="NO ANSWER" && (<Tooltip title={t("No Answer")}><PhoneMissedIcon className='disposition-icon' style={{ color: "red",fontSize: "19px" }} /></Tooltip>) }
              {item.disposition==="BUSY" && (<Tooltip title={t("Busy")}><PhonePausedIcon className='disposition-icon' style={{ color: "orange",fontSize: "19px" }} /></Tooltip>) }
              {item.disposition==="FAILED" && (<Tooltip title={t("Call Failed")}><PhoneDisabledIcon className='disposition-icon' style={{ color: "red",fontSize: "19px" }} /></Tooltip>) }
              </div>
              </div>
                    </td>
                    <td>{item.duration} {'sec'}</td>
                    <td className="playback-column">
                      {item.disposition !== "ANSWERED" ? (
                        "-"
                      ) : <AudioPlayer recordingUrl={item.recordingfile} />}
                    </td>
                    <td>
                      {item.transcript ? (
                        <div>
                          <Tooltip title={t("Transcription")}><VoiceChatIcon className='voice-chat' style={{ cursor: "pointer" }} onClick={() => handleModalTranscription(item.uniqueid)} /></Tooltip>
                          <Tooltip title={t("Summary")}><SummarizeIcon style={{ cursor: "pointer" }} onClick={() => handleModalTranscriptionSummary(item.uniqueid)} /></Tooltip>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        ) : (
          <Form.Control
            as="textarea"
            rows={3}
            style={{width: '465px', height: '200px', overflow: 'auto' }}
            value={notes}
            readOnly
          />
        )}
        
        {viewModalState === 'history' && currentItems?.length !== 0 && (
          <ReactPaginate
            previousLabel={'<<'}
            nextLabel={'>>'}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={pageCount === 1 ? 'd-none' : 'pagination'}
            activeClassName={'active'}
            previousClassName={currentPage === 0 ? 'd-none' : 'page-item'}
            nextClassName={currentPage === pageCount - 1 ? 'd-none' : 'page-item'}
          />
        )}
      </>
      
      )}
          </div>
        </Modal.Body>
        <Modal.Footer>
        {viewModalState !== 'notes' && (
        <div className="px-2 time-period">
        <Dropdown>
              <Dropdown.Toggle className="drop-color-vm" id="dropdown-basic">
              {selectedPeriod === null ? 'Select Time Period' : selectedPeriod === 0 ? t("This Week") : selectedPeriod === 1 ? t("One Month") : selectedPeriod === 2 ? t("Six Months") : t("All")}
              </Dropdown.Toggle>
      
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => timePeriodChange(0)}>{t("This Week")}</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => timePeriodChange(1)}>{t("One Month")}</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => timePeriodChange(2)}>{t("Six Months")}</Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => timePeriodChange(3)}>{t("Show All")}</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
            </div>
        )}
          <Button  variant={viewModalState==='notes' ? "primary" :"secondary"} onClick={handleClose}>
          {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <CallHistoryModal show={showModal} onHide={() => setShowModal(false)} uniqueId={uniqueId} modalState={modalState}/>
    </>
  );
};

export default ViewModal;