import io from 'socket.io-client';

let socket:any;

// Stream Audio
let bufferSize: number = 2048,
  AudioContext: any,
  context: AudioContext | any,
  processor: ScriptProcessorNode | any,
  input: MediaStreamAudioSourceNode | any,
  globalStream: MediaStream;

const mediaConstraints: MediaStreamConstraints = {
  audio: true,
  video: false
};

interface TranscribeConfig {
  // Define your transcribeConfig properties here
}

interface SpeechData {
  data: any;
  isFinal: boolean;
}

interface AudioStreamerI {
  initRecording(transcribeConfig: TranscribeConfig, onData: (data: any, isFinal: boolean) => void,ws_url: string, onError: (error: string) => void): void;
  stopRecording(): void;
}

const AudioStreamer: AudioStreamerI = {
  initRecording(transcribeConfig: TranscribeConfig, onData: (data: any, isFinal: boolean) => void,ws_url: string, onError: (error: string) => void) {
    socket = io(ws_url, {transports: ['websocket']});
    socket.emit('startGoogleCloudStream', { ...transcribeConfig });
    AudioContext = window.AudioContext ;
    context = new AudioContext();
    processor = context.createScriptProcessor(bufferSize, 1, 1);
    processor.connect(context.destination);
    context.resume();

    const handleSuccess = function (stream: MediaStream) {
      globalStream = stream;
      input = context.createMediaStreamSource(stream);
      input.connect(processor);

      processor.onaudioprocess = function (e: AudioProcessingEvent) {
        microphoneProcess(e);
      };
    };

    navigator.mediaDevices.getUserMedia(mediaConstraints)
      .then(handleSuccess);

    if (onData) {
      socket.on('speechData', (response: SpeechData) => {
        onData('\nAgent: ' + response.data, response.isFinal);
      });
    }

    socket.on('googleCloudStreamError', (error: string) => {
      if (onError) {
        onError(error);
      }
      closeAll();
    });

    socket.on('endGoogleCloudStream', () => {
      closeAll();
    });
  },

  stopRecording() {
    socket.emit('endGoogleCloudStream');
    closeAll();
  }
};

export default AudioStreamer;

// Helper functions
/**
 * Processes microphone data into a data stream
 *
 * @param {AudioProcessingEvent} e Input from the microphone
 */
function microphoneProcess(e: AudioProcessingEvent) {
  const left: Float32Array = e.inputBuffer.getChannelData(0);
  const left16: ArrayBuffer = convertFloat32ToInt16(left);
  socket.emit('binaryAudioData', left16);
}

/**
 * Converts a buffer from float32 to int16. Necessary for streaming.
 * sampleRateHertz of 1600.
 *
 * @param {Float32Array} buffer Buffer being converted
 */
function convertFloat32ToInt16(buffer: Float32Array): ArrayBuffer {
  let l: number = buffer.length;
  let buf: Int16Array = new Int16Array(Math.ceil(l / 3));

  for (let i = 0; i < l; i += 3) {
    buf[i / 3] = Math.max(-1, Math.min(1, buffer[i])) * 32767;
  }

  return buf.buffer;
}

/**
 * function convertFloat32ToInt16(buffer: Float32Array): ArrayBuffer {
  let l: number = buffer.length;
  let buf: Int16Array = new Int16Array(l / 3);

  while (l--) {
    if (l % 3 === 0) {
      buf[l / 3] = buffer[l] * 0xFFFF;
    }
  }
  return buf.buffer;
}
 */
/**
 * Stops recording and closes everything down. Runs on error or on stop.
 */
function closeAll() {
  // Clear the listeners (prevents issue if opening and closing repeatedly)
  socket.off('speechData');
  socket.off('googleCloudStreamError');
  let tracks: MediaStreamTrack[] | null = globalStream ? globalStream.getTracks() : null;
  let track: MediaStreamTrack | null = tracks ? tracks[0] : null;
  if (track) {
    track.stop();
  }

  if (processor) {
    if (input) {
      try {
        input.disconnect(processor);
      } catch (error) {
        console.warn('Attempt to disconnect input failed.');
      }
    }
    processor.disconnect(context.destination);
  }
  if (context) {
    context.close().then(() => {
      input = null;
      processor = null;
      context = null;
      AudioContext = null;
    });
  }
  socket.disconnect();
}
