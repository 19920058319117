import React, { useContext, useState, useEffect } from "react";
import TopNavbar from "./TopNavbar";
import { useNavigate } from "react-router-dom";
import Dialer from "../phone/Dialer";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
// import Kiosks from "./kiosk/kiosks";
import CallHistoryTable from "../callhistory/CallHistoryTable";
import ContactsTable from "../Contacts/ContactTable";
import "react-toastify/dist/ReactToastify.css";
import DashboardModal from "./DashboardModal";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
const Dashboard = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [phoneRing, setPhoneRing] = useState(false);
  const [token, setToken] = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState("callHistory");
  const [isMicrophoneConnected, setIsMicrophoneConnected] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en-US");
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const[contactAdded,setContactAdded] = useState([]);
  const [selectedContactNumber, setSelectedContactNumber] = useState<string>('');

  useEffect(() => {
    const sendHeartbeat = async () => {
      try {
        await axios.post(
          '/api/session/heartbeat',
          { token: token },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token || "",
            },
          }
        );
      } catch (error:any) {
        if (error.response && error.response.status === 401) {
          // handle expired token
          Cookies.remove("Token");
          navigate("/");
        }
      }
    };

        // Start sending heartbeat every 5 minutes
        const heartbeatInterval = setInterval(sendHeartbeat, 10*60 * 1000);

        // const handleUnload = async () => {
        //     // Notify backend about session end or browser close
        //     try {
        //       const response = await axios.post(
        //         "/api/softphone/logout",
        //         {},
        //         {
        //           headers: {
        //             "Content-Type": "application/json",
        //             Authorization: token || "",
        //           },
        //         }
        //       );
              
        //     } catch (error: any) {
        //       console.error("An unexpected error occurred:", error);
        //     }
        
        //     Cookies.remove('Token');
        // };

        // // Listen for beforeunload/unload event
        // window.addEventListener('beforeunload', handleUnload);

        // Cleanup event listener on component unmount
        return () => {
            clearInterval(heartbeatInterval);
            // window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);
  const dashboard_interface = async () => {
    try {
      await axios.get("/api/user/dashboard", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });

      navigate("/Kiosk");
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          navigate("/Dashboard");
        } else if (
          error.response.data &&
          error.response.data.error_description
        ) {
          setErrorMessage(error.response.data.error_description);
        } else {
          setErrorMessage("Login failed.");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  const handleToggleSidebar = () => {
    setSidebar(!sidebar);
  };
  const handleTabClick = (tab: React.SetStateAction<string>) => {
    setSelectedTab(tab);
  };
  const handleCallContact = (number: string) => {
    setSelectedContactNumber(number);
  };
  const fetchDefaultLanguage = async () => {
    try {
      const response = await axios.get(`/api/softphone/user/languages`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });

      setSelectedLanguage(response.data.user_default_language);
      return true;
    } catch (error) {
      console.log("Error fetching transcription data:", error);
    }
  };

  useEffect(() => {
    fetchDefaultLanguage();
  }, [selectedLanguage]);

  useEffect(() => {
    if (token === "") {
      navigate("/");
    }

    dashboard_interface();
  }, [token]);

  useEffect(() => {
    const checkMicrophoneStatus = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (stream) {
          setIsMicrophoneConnected(true);
          setShowAlert(false);
          stream.getTracks().forEach((track) => track.stop());
        }
      } catch (error) {
        setIsMicrophoneConnected(false);
        setShowAlert(true);
      }
    };

    checkMicrophoneStatus();
    const intervalId = setInterval(() => {
      checkMicrophoneStatus();
  },1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
    <ToastContainer className={"custom-toast"} limit={1} />
      <div>
        <div className="dashboard-bg">
          <div>
            <TopNavbar showModal={showModal} setShowModal={setShowModal} />
          </div>
          <div className="dashboard-container">
            <Dialer
              setSidebar={setSidebar}
              isCalling={isCalling}
              setIsCalling={setIsCalling}
              phoneRing={phoneRing}
              setPhoneRing={setPhoneRing}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              showAlert={showAlert}
              contactAdded={contactAdded}
              setContactAdded={setContactAdded}
              selectedContactNumber={selectedContactNumber}
            />
            <div className="pn-table">
  <div className="tabs-container">
    <button
      className={`tab-button ${selectedTab === "callHistory" ? "selected" : ""}`}
      onClick={() => handleTabClick("callHistory")}
    >
      {t("Call History")}
    </button>
    <button
      className={`tab-button ${selectedTab === "contacts" ? "selected" : ""}`}
      onClick={() => handleTabClick("contacts")}
    >
      {t("Contacts")}
    </button>
    {selectedTab === "contacts" && (
      <ContactsTable
        contactAdded={contactAdded}
        onCallContact={handleCallContact}
      />
    )}
    {selectedTab === "callHistory" && <CallHistoryTable />}
  </div>
</div>

          </div>
        </div>
      </div>
      <DashboardModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        setSelectedLanguage={setSelectedLanguage}
        selectedLanguage={selectedLanguage}
      />
    </>
  );
};

export default Dashboard;
