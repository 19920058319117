// src/App.tsx

import React from 'react';
import CallComponent from './components/login/Login';
import Homepage from './components/homepage/homepage';
import UserRegistration from './components/UserRegistration/UserRegistration';



const App: React.FC = () => {
  return (
    <div>
    <Homepage/>
  </div>
  );
};

export default App;
