import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEnglish from './translation/english/translation.json';
import translationJapanese from './translation/japanese/translation.json';

const resources = {
    en: {
        translation: translationEnglish,
    },
    ja: {
        translation:translationJapanese,
    }
}

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug:false,
    fallbackLng:"en",
    resources,
})

export default i18n;