import React from 'react';
import './file.css';
import { useNavigate } from "react-router-dom";
import logo from './images/logo.svg';
const Header: React.FC = () => {
    const navigate = useNavigate();
    const handleAdminLoginClick = () => {
        window.location.href = '/admin/register'; 
      };
    
  return (
    <div className="homepage-header">
      <img className="logo" src={logo} alt="pbxAI" />
        <div className="buttons">
          <button className="login-btn" onClick={() => navigate("/")}>Log In</button>
          <button className="free-btn" onClick={()=> navigate("/UserRegistration")}>Get it Free</button>
        </div>
    </div>
  );
};

export default Header;
