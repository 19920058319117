import React, { createContext, useEffect, useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

const defaultValue = [null, () => {}];
export const UserContext = createContext<any>(defaultValue);

export const UserProvider = (props:any) => {
  const [token, setToken] = useState(Cookies.get('Token'));

  useEffect(() => {
    

    const fetchUser = async () => {
      try {
        const response = await axios.get("/api/users/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: (token || ""),
          }
        });
        Cookies.set("Token", (token || ""));
        // localStorage.setItem("Token", (token || ""));
      } catch (error) {
        setToken("");
        Cookies.set("Token", (token || ""));
        // localStorage.setItem("Token", (token || ""));
      }
    };
    fetchUser();
  }, [token]);

  return (
    <UserContext.Provider value={[token, setToken]}>
      {props.children}
    </UserContext.Provider>
  );
};



