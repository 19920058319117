import React, { useContext, useState, useEffect, useRef, FC } from "react";
import IncomingDialer from "./IncomingDialer";
import JsSIP from "jssip";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPhone} from "@fortawesome/free-solid-svg-icons";
import speechToTextUtils from "./google_utility_transcribe";
import io from "socket.io-client";
import TranscribeSection from "./TranscribeSection";
import { faDeleteLeft, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Portrait_Placeholder from "../phone/icon/Portrait_Placeholder.png";
import Workspace from "./Workspace";
import CallTransferModal from "./CallTransferModal"; 
import {useTranslation} from "react-i18next";
import { Tooltip } from "@mui/material";
// JsSIP.debug.enable("JsSIP:*");

interface DialerProps {
  setSidebar: (value: boolean) => void;
  isCalling: boolean;
  setIsCalling: (value: boolean) => void;
  phoneRing: boolean;
  setPhoneRing: (value: boolean) => void;
  selectedLanguage: string;
  setSelectedLanguage: (value: string) => void;
  showAlert:boolean;
  contactAdded:any;
  setContactAdded:any;
  selectedContactNumber:string;
}

interface SpeechData {
  data: any;
  isFinal: boolean;
}

let data: any;
let options: any;
let ua: any;
let config: any;

let session: any = null;
let audioStreamSocket: any;
let ws_url: string;
let transcript: string = "";
let audioContext: AudioContext | null = null;
let scriptProcessorNode: ScriptProcessorNode | null = null;
let mediaStreamSource: MediaStreamAudioSourceNode | null = null;

const Dialer: FC<DialerProps> = ({
  setSidebar,
  isCalling,
  setIsCalling,
  phoneRing,
  setPhoneRing,
  selectedLanguage,
  setSelectedLanguage,
  showAlert,
  contactAdded,
  setContactAdded,
  selectedContactNumber,
}) => {

  
  
  const [callStatus, setCallStatus] = useState("");
  const [number, setNumber] = useState("");
  const [connected, setConnected] = useState(false);
  const [incomingCallSession, setIncomingCallSession] = useState(false);
  const [outgoingCallSession, setOutgoingCallSession] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [answering, setAnswering] = useState(false);
  // const [phoneRing, setPhoneRing] = useState(false);
  const [timer, setTimer] = useState(0);
  const [token, setToken] = useContext(UserContext);
  const [hostname, setHostname] = useState("");
  const [transcriptionToggle, setTranscriptionToggle] = useState(false);
  const [transcriptionToggleVisibility, setTranscriptionToggleVisibility] = useState(false);
  const [contactDetail,setContactDetail] = useState([]);
  const [phoneNumber,setPhoneNumber] = useState("");
  const [transcribedData, setTranscribedData] = useState<string[]>([]);
  const[id,setId] = useState("");
  const [name,setName] = useState("");
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [existingContact, setExistingContact] = useState(false);
  const [workspace,setWorkspace] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  // const [interimTranscribedData, setInterimTranscribedData] = useState<string>('');
  // const [isRecording, setIsRecording] = useState<boolean>(false);

  // Use a ref to store the audio element for Jssip calling
  const audioRef = useRef<HTMLAudioElement | null>(null);
  // Use ref to get the audio element
  const audioElement: any = audioRef.current;

  //audio ref for ringtone
  const audioRef2 = useRef<HTMLAudioElement | null>(null);

  //audio ref for busy tone
  const audioRef3 = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    return () => {
      if (ua && token === "") {
        ua.stop();
        ua = null;
      }
    };
  }, [token]);

  // Reference comment please do not remove
  // const flushInterimData = () => {
  //   if (interimTranscribedData !== '') {
  //     setInterimTranscribedData('');
  //     setTranscribedData(oldData => [...oldData, interimTranscribedData]);
  //   }
  // };
  const [longPress, setLongPress] = useState(false);
  const [pressTimeout, setPressTimeout] = useState<number | null>(null);
  const {t} = useTranslation();

  const handleMouseDown = (number: string) => {
    if (number === "0") {
      const timeoutId = window.setTimeout(() => {
        setLongPress(true);
        addToPhoneNumber("+");
      }, 500);
      setPressTimeout(timeoutId);
    } else {
      addToPhoneNumber(number);
    }
  };

  const handleMouseUp = (number: string) => {
    if (pressTimeout !== null) {
      clearTimeout(pressTimeout);
      setPressTimeout(null);
    }
    if (!longPress && number === "0") {
      addToPhoneNumber(number);
    }
    setLongPress(false);
  };
  const handleDataReceived = (data: string, isFinal: boolean) => {
    if (isFinal) {
      if (
        data !== "\nAgent: ," &&
        data !== "\nCustomer: ," &&
        data !== "\nCustomer: " &&
        data !== "\nAgent: "
      ) {
        setTranscribedData((oldData) => [...oldData, data]);
        transcript = transcript + data;
      }
    }
    // Reference comment please do not remove
    // if (isFinal) {
    //   setInterimTranscribedData('');

    //   setTranscribedData(oldData => [...oldData, data]);

    // } else {
    //   setInterimTranscribedData(data);
    // }
  };

  const getTranscriptionConfig = () => {
    return {
      audio: {
        encoding: "LINEAR16",
        sampleRateHertz: 16000,
        languageCode: selectedLanguage,
        model: "telephony",
      },
      interimResults: true,
    };
  };

  useEffect(() => {
    if(transcriptionToggleVisibility) {
      if (transcriptionToggle) {
        onStart();
      } else {

        onStop();
        setTranscriptionToggleVisibility(false);
      }
    }
    
  },[transcriptionToggle])
  
  const onStart = () => {
    setTranscribedData([]);
    // setIsRecording(true);
    transcript = ""; //initializing/reseting the transcript variable
    speechToTextUtils.initRecording(
      getTranscriptionConfig(),
      handleDataReceived,
      ws_url,
      (error: string) => {
        console.error("Error when transcribing", error);
        // setIsRecording(false);
        // No further action needed, as stream already closes itself on error
      }
    );

    //audioStream WebSocket connection open initalizations
    audioStreamSocket = io(ws_url, { transports: ["websocket"] });
    const transcribeConfig = getTranscriptionConfig();
    audioStreamSocket.emit("startGoogleCloudStream", { ...transcribeConfig });
    audioStreamSocket.on("speechData", (response: SpeechData) => {
      handleDataReceived("\nCustomer: " + response.data, response.isFinal);
    });

    audioStreamSocket.on("googleCloudStreamError", (error: string) => {
      if (error) {
        console.error("Error when transcribing", error);
      }
      closeAllaudioStreamSocket();
    });

    audioStreamSocket.on("endGoogleCloudStream", () => {
      closeAllaudioStreamSocket();
    });
  };

  const onStop = () => {
    // flushInterimData(); // A safety net if Google's Speech API doesn't work as expected, i.e. always sends the final result
    
   
    closeAllaudioStreamSocket();
    
    if (audioElement) {
      audioElement.srcObject = null; //To stop the handleaudiostream()
      
    }
    if (audioContext) {
      audioContext.close();
      audioContext = null;
    }
    if (scriptProcessorNode) {
      scriptProcessorNode.disconnect();
      scriptProcessorNode = null;
    }
    if (mediaStreamSource) {
      mediaStreamSource.disconnect();
      mediaStreamSource = null;
    }
    
    if (transcript !== "") {
      saveTranscription(); // function to save the transcription record
    }
    
  };

  const fetchurl = async () => {
    try {
      const response = await axios.get("/api/link", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      ws_url = response.data.url;
    } catch (error) {
      console.error(error);
    }
  };

  const saveTranscription = async () => {
    // api to save the transcription record
    try {
      let transcriptionPayload = { transcribedText: transcript };
      const response = await axios.post(
        "/api/user/extension/transcription/add",
        transcriptionPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const api = async () => {
    try {
      const response = await axios.get("/api/user/config", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      data = response.data;
      setHostname(data.hostname);
      //let ice:any = data.stun_turn_servers.replace(/"/g, '');
      // Initialize JsSIP configuration
      JsSIP.debug.disable(); // Disabling debug
      config = {
        sockets: [
          new JsSIP.WebSocketInterface(
            "wss://" + data.hostname + ":" + data.ws_port + "/ws"
          ),
        ],
        uri: "sip:" + data.webrtc_extension + "@" + data.hostname,
        password: data.webrtc_extension_password,
        realm: data.hostname,
        connection_recovery_max_interval: 60,
        connection_recovery_min_interval: 4,
        contact_uri: "sip:" + data.webrtc_extension + "@" + data.hostname,
        no_answer_timeout: 30,
        session_timers: true,
        session_timers_force_refresher: false,
        session_timers_refresh_method: "update",
        register: true,
        register_expires: 172800,
        registrar_server: "sip:" + data.hostname,
      };

      // options for WebRTC calling
      options = {
        mediaConstraints: { audio: true, video: false },
        pcConfig: {
          iceServers: [
            { urls: "stun:" + data.stun_address },
            {
              urls: "turn:" + data.turn_address,
              username: data.turn_username,
              credential: data.turn_password,
            },
          ],
        },
        iceTransportPolicy: "all",
        rtcpMuxPolicy: "negotiate",
        rtcOfferConstraints: { offerToReceiveAudio: true },
        mandatory: {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: false,
          DtlsSrtpKeyAgreement: true,
        },
      };

      ua = new JsSIP.UA(config);
      ua.start();
      initializeRTC();
    } catch (error: any) {
      console.log(error);
    }
  };

  const addToPhoneNumber = (num: string) => {
    setNumber(number + num);
  };
  const handleClear = () => {
    setNumber((prevNumber) => prevNumber.slice(0, -1));
  };
  const handleFullClear = () => {
    setNumber("");
  };

  const hangupCall = () => {
    if (outgoingCallSession && !phoneRing) {
      try {
        session.terminate();
      } catch (e) {
        console.log("Failed to terminate outgoing: ", e);
      }
      setIsCalling(false);
    }

    if (incomingCallSession && phoneRing) {
      try {
        session.terminate();
      } catch (e) {
        console.log("Failed to terminate incoming: ", e);
      }
      setPhoneRing(false);
      setSidebar(true);
      setAnswering(false);
    }
    setIsOnHold(false);
  };

  const holdCallButton = () => {
    if (outgoingCallSession && !phoneRing) {
      try {
        if (isOnHold) {
          session.unhold();
          setIsOnHold(false);
        } else {
          session.hold();
          setIsOnHold(true);
        }
      } catch (e) {
        console.log("Failed to hold outgoing: ", e);
      }
    }

    if (incomingCallSession && phoneRing) {
      try {
        if (isOnHold) {
          session.unhold();
          setIsOnHold(false);
        } else {
          session.hold();
          setIsOnHold(true);
        }
      } catch (e) {
        console.log("Failed to hold incoming: ", e);
      }
    }
  };

  const transferCall = (extension: string) => {
    if (outgoingCallSession || incomingCallSession) {
      try {
         // Send the DTMF sequence for transfer
         session.sendDTMF('*2');
         setTimeout(() => {
          try{
            session.sendDTMF(extension);
          }catch(e){
            console.log("Failed to tranfer: ", e);
          }
             
         }, 1500); // Delay of 1.5 second before sending the extension for call transfer voice over
         
      } catch (e) {
        console.log("Failed to tranfer: ", e);
      }
    }};

  const phone_number_details = async( phone_number: string) => {
    try {

      const response = await axios.post(
        "/api/user/dialer/contact/details",
        { phone_number: phone_number },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        }
      );
      const contactDetail = response.data;
      setContactDetail(contactDetail);
      setId(contactDetail.contact_details.id);
      setName(contactDetail.contact_details.name);
      setEmail(contactDetail.contact_details.email);
      setPhone(contactDetail.contact_details.phone);
      setTitle(contactDetail.contact_details.title);
      setCompany(contactDetail.contact_details.company);
      setNotes(contactDetail.contact_details.notes);
      setExistingContact(true);
      // console.log('contactdetail',contactDetail.contact_details.name);
    } catch (error:any) {
      if (error.response) {
        if (error.response.status === 404) {
          const phoneNumber = phone_number;
          setPhoneNumber(phoneNumber);
          setContactDetail([]);
          setName("");
          setEmail("");
          setPhone("");
          setTitle(""); 
          setCompany("");
          setNotes("");
          setExistingContact(false);
          // console.log(phoneNumber,"phoneNumber");
        } else {
          //console.log()
        }
      } else {
        console.log("Error:", error.message);
      }
    }
  }

  const initializeRTC = () => {
    // Register SIP callbacks for connection events
    ua.on("registered", () => {
      setConnected(true);
    });

    ua.on("registrationExpiring", () => {
      // Re-register the UA to renew the registration
      ua.register();
      
    });

    ua.on("registrationFailed", (e: any) => {
      setConnected(false);
      console.log(`Disconnected cause: ${e}`);
    });

    ua.on("connecting", () => {
      console.log("Connecting to WebSocket...")
    });
    ua.on("connected", () => {
      setConnected(true);
      console.log("WebSocket Connected")
    });
    ua.on("disconnected", () => {
      setConnected(false);
      console.log("WebSocket Disconnected")
    });

    //Event listener for RTC calls
    ua.on("newRTCSession", function (data: any) {
      const newSession = data.session;

      // Check if there's already an active session
      if (session !== null) {
        console.log("Line is busy. Rejecting the incoming call.");
        newSession.terminate({
          status_code: 486, // Busy Here
          reason_phrase: "Busy Here"
        });
        return;
      }
    
      // Assign the new session
      session = newSession;
      
      let myCandidateTimeout: any = null;
      // Important fix for removing delay in connecting inbound/outbound calls
      session.on("icecandidate", function (candidate: any) {
        //console.log("getting a candidate" + candidate);
        if (myCandidateTimeout != null) clearTimeout(myCandidateTimeout);
        // 2 second timeout after the last icecandidate received!
        myCandidateTimeout = setTimeout(candidate.ready, 2000);
      });

      if (session.direction === "incoming") {
        // Store the incoming session for later handling
        setIncomingCallSession(true);
        // You can trigger a ringtone or show a notification to indicate an incoming call
        setPhoneRing(true);
        setSidebar(false);
        phone_number_details(session.remote_identity.uri.user);
        setWorkspace(true);
      }

      if (session.direction === "outgoing") {
        // Store the outgoing session for later handling
        setOutgoingCallSession(true);
      }

      session.on("ended", function (e: any) {
        console.log(`Call ended with cause: ${e.cause}`);
        const audio: any = audioRef2.current;
        if (audio) {
          audio.pause(); // Pause the audio if it's playing
        }
        setCallStatus("Call ended");
        //outgoing state changes
        setIsCalling(false);
        //incoming state changes
        setPhoneRing(false);
        setSidebar(true);
        setAnswering(false);
        //clearing Audio stream socket
        // setIsRecording(false);


          setTranscriptionToggle(false);
        
          session = null;
      });
      session.on("failed", function (e: any) {
        console.log(`Call failed with cause: ${e.cause}`);
        //if the caller is busy play the busy audio
        if (e.message && e.message.status_code === 486) {
          const audioBusy: any = audioRef3.current;
          audioBusy.src = "../../assets/call/phone-internal-busy.mp3"; 
          audioBusy.play().catch((error: any) => {
            console.error("Error playing audio:", error);
            // Handle the error (e.g., show a notification to the user)
          });
          setTimeout(() => {
            if (audioBusy) {
              audioBusy.pause(); // Pause the audio if it's playing
            }
          }, 3000);
          
        }
        const audio: any = audioRef2.current;
        if (audio) {
          audio.pause(); // Pause the audio if it's playing
        }
        setCallStatus("Call failed");
        //outgoing state changes
        setIsCalling(false);
        //incoming state changes
        setPhoneRing(false);
        setSidebar(true);
        setAnswering(false);
        

          setTranscriptionToggle(false);
          session = null;
      });
      session.on("confirmed", function (e: any) {
        const audio: any = audioRef2.current;
        if (audio) {
          audio.pause(); // Pause the audio if it's playing
        }
        setTranscriptionToggleVisibility(true);
        setCallStatus("Call confirmed");
      });
      session.on("progress", function (e: any) {
        const audio: any = audioRef2.current;
        if (session.direction === "outgoing") {
          const audioFilePath = "../../assets/call/phone-internal-ringing.mp3"; // Define the path here
          audio.src = audioFilePath; // Set the source dynamically
        }
        if (session.direction === "incoming") {
          const audioFilePath =
            "../../assets/call/office_phone-ring_medium.mp3"; // Define the path here
          audio.src = audioFilePath; // Set the source dynamically
        }

        audio.loop = true; // Set loop to true for continuous playback
        audio.play().catch((error: any) => {
          console.error("Error playing audio:", error);
          // Handle the error (e.g., show a notification to the user)
        });
        console.log("call is in progress..");
        setCallStatus("Call in progress");
      });
    });
  };
  // incoming call handling
  // Function to answer an incoming call
  const answerCall = () => {
    if (incomingCallSession) {
      session.answer(options);
      // Handle audio stream in the 'addstream' event when inbound/outbound call is confirmed
      session.connection.addEventListener("addstream", (event: any) => {
        if (audioElement) {
          audioElement.srcObject = event.stream;
          audioElement.play();
          // Handle the audio stream
          handleAudioStream(event.stream);
        }
      });
      setAnswering(true);
      setPhoneRing(true);
    }
  };
   


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9*#+]*$/;
    if (regex.test(value)) {
      setNumber(value);
    }
  };

  const requestNotificationPermission = async () => {
    if (Notification.permission !== "granted") {
      try {
        await Notification.requestPermission();
      } catch (error) {
        console.error("Notification permission request failed:", error);
      }
    }
  };
  const showIncomingCallNotification = () => {
    if (Notification.permission === "granted" && incomingCallSession  &&
    document.visibilityState === "hidden") {
      const notification = new Notification(
        `Incoming Call from ${session.remote_identity.display_name || session.remote_identity.uri.user}`,
        {
          body: "Click this window to answer or close this window to ignore",
          icon: Portrait_Placeholder, 
          requireInteraction: true, 
        }
      );
      notification.onclick = () => {
        window.focus(); 
        answerCall(); 
      };
      setTimeout(() => {
        if (notification) {
          notification.close();
        }
      }, 10000);
    }
  };
  useEffect(() => {
    requestNotificationPermission();
  }, []);
  
  useEffect(() => {
   
    if (phoneRing && incomingCallSession) {
      showIncomingCallNotification();
    }
  }, [phoneRing, incomingCallSession]);
  
  //outgoing call
  const startCall = () => {
    // Make a call
    ua.call(`sip:${number}@${hostname}`, options);
    session.connection.addEventListener("addstream", (event: any) => {
      if (audioElement) {
        audioElement.srcObject = event.stream;
        audioElement.play();
        // Handle the audio stream
        handleAudioStream(event.stream);
      }
    });

    setIsCalling(true);
  };
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    // let token = Cookies.get('Token');

    if (callStatus === "Call confirmed") {
      // Start the timer
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
      setTranscriptionToggleVisibility(true);

    } else {
      // Initialize intervalId to avoid the TypeScript error
      intervalId = setInterval(() => {});
      // Clear the timer when the call status changes
      clearInterval(intervalId);
      setTimer(0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [callStatus]);

  useEffect(() => {
    api();
    fetchurl();
  }, []);
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;

    return formattedTime;
  };

  useEffect(() => {
    if (selectedContactNumber) {
      setNumber(selectedContactNumber);
    }
  }, [selectedContactNumber]);
  return (
    <div className="dialer-container">
    <div className=" dialer-width">
      <span className="dialer-heading">
        <h4
          className="dialer-text"
        >
          {" "}   
          {t("Dialer")} 
        </h4>
        <Tooltip title={connected ? t("Connected") : t("Disconnected")}>
        {callStatus === "Call confirmed" || !phoneRing ? (
           
          <FontAwesomeIcon
          className={`${connected?"dialer-icon":"dialer-icon red-clr"}`}
            icon={faPhone}
            fade={!connected}
          />
        ) : (

          <FontAwesomeIcon
          className={`${connected?"dialer-icon":"dialer-icon red-clr"}`}
            icon={faPhone}
            shake
          />
          
        )}
        </Tooltip>
      </span>
      {showAlert&&
      <div className="showAlert">
      <FontAwesomeIcon icon={faCircleExclamation} size="lg"/>
      <h6 className="pdleft">{t("Microphone disconnected")}</h6>
      </div>
      }
      <div className="center-dialer">
        {phoneRing && IncomingDialer && (
          <div
            className={` ${
              phoneRing ? "answering" : "not-answering"
            }`}
          >
            <IncomingDialer
              session={session}
              answerCall={answerCall}
              answering={answering}
              hangupCall={hangupCall}
            />
          </div>
        )}

        <div  className={` ${
              showAlert ? "sidebar-1" : "sidebar-mr"
            }`}>
          {incomingCallSession && answering && (
            <div className="call-notification-text">
              {/* {session.remote_identity.display_name && (
                <div>{session.remote_identity.display_name}</div>
              )} */}
              <div>{session.remote_identity.uri.user}</div>
            </div>
          )}
          <p className="timer-box">
            {callStatus === "Call confirmed" ? formatTime(timer) : ""}
          </p>

          <div
            className={` ${
              phoneRing && !answering ? "display-none" : "input-section"
            }`}
          >
            <div>
              <input
                type="text"
                className="call"
                id="input-box"
                name="input-box"
                value={number}
                maxLength={100}
                onChange={handleChange}
                placeholder={t("Enter phone number")}
              />
            </div>
            {number && (
              <div
                className={` ${
                  answering ? "display-none" : "input-group-close"
                }`}
              >
                <FontAwesomeIcon
                  className="clear-icon"
                  icon={faDeleteLeft}
                  style={{ color: "#236ca4" }}
                  onClick={handleClear}
                />
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ color: "#236ca4" }}
                  onClick={handleFullClear}
                />
              </div>
            )}
          </div>
          <div
            className={` ${
              phoneRing && !answering ? "display-none" : "dial-pad"
            }`}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((number) => (
              <button
                key={number}
                onMouseDown={() => handleMouseDown(String(number))}
                onMouseUp={() => handleMouseUp(String(number))}
                onMouseLeave={() => {
                  if (pressTimeout !== null) {
                    clearTimeout(pressTimeout);
                    setPressTimeout(null);
                  }
                  setLongPress(false);
                }}
                className="dial-button"
              >
                {number === 0 ? (
                  <div>
                    <div className="zero-num">0</div>
                    <div className="plus-sign">+</div>
                  </div>
                ) : (
                  number
                )}
              </button>
            ))}
          </div>

          <div className={` ${phoneRing ? "display-none" : "pt-4 width-74"}`}>
            {isCalling ? (
              <div>
                <button
                  // style={{ backgroundColor: "#0161b1" }}
                  type="button"
                  className={`${
                    callStatus === "Call confirmed"
                      ? "display-none"
                      : "btn-danger btn btn-primary full-width"
                  }`}
                  onClick={hangupCall}
                >
                  {t("HangUp")}
                </button>
                {/* <p className="call-status">{callStatus}</p> */}
              </div>
            ) : (
              <button
                style={{ backgroundColor: "#0161b1" }}
                type="button"
                className={`${
                  callStatus === "Call confirmed"
                    ? "display-none"
                    : "dialer-call-b btn btn-primary w-70"
                }`}
                onClick={startCall}
                disabled={!number}
              >
                {t("Call")}
              </button>
            )}
          </div>
        </div>
        {/* Add an audio element with a ref */}
        <audio ref={audioRef} id="audio-element"></audio>
        <audio ref={audioRef2} id="audio-element2"></audio>
        <audio ref={audioRef3} id="audio-element3"></audio>
         {callStatus === "Call confirmed" ? ( 
          
            <div className="pt-2">
                <div className="center-buttons">
                <button
                    type="button"
                    onClick={holdCallButton}
                    className="btn btn-warning button-wid"
                  >
                    {isOnHold ?
                      (
                        t("Resume")
                      )
                      :
                      (
                        t("Hold")
                      )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-2 button-wid"
                    onClick={() => setShowModal(true)}
                  >
                    {t("Transfer")}
                  </button>
                  
                </div>
                <div className="transfer-call-button">
                <button
                    style={{ paddingTop:"10px"}}
                    type="button"
                    className="dialer-call-b btn btn-danger  w-70"
                    onClick={hangupCall}
                    >
                    {t("HangUp")}
                    
                </button>
                <CallTransferModal showModal={showModal} setShowModal={setShowModal}  transferCall={transferCall} />
                </div>

                {/* <p className="call-confirm common-clr">{callStatus}</p> */}
              </div>
              
        ) : (
          ""
        )}
      </div>
      {}
      <TranscribeSection
        transcribedData={transcribedData}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        transcriptionToggleVisibility={transcriptionToggleVisibility}
        transcriptionToggle={transcriptionToggle}
        setTranscriptionToggle={setTranscriptionToggle}
        callStatus={callStatus}
      />
    </div>
    <div className={"dialer-width"}>
      <Workspace
      contactDetail={contactDetail}
       setContactDetail={setContactDetail}
      contactAdded={contactAdded}
      setContactAdded={setContactAdded}
       phoneNumber={phoneNumber}
       setPhoneNumber={setPhoneNumber}
       id={id}
       name={name}
       setName={setName}
       phone={phone}
       setPhone={setPhone}
       email={email}
       setEmail={setEmail}
       title={title}
       setTitle={setTitle}
       company={company}
       setCompany={setCompany}
       notes={notes}
       setNotes={setNotes}
       existingContact={existingContact}
       setExistingContact={setExistingContact}
       incomingCallSession={incomingCallSession}
      />
    </div>
    </div>
  );
};

export default Dialer;

/**
 * Converts a buffer from float32 to int16. Necessary for streaming.
 * sampleRateHertz of 1600.
 *
 * @param {Float32Array} buffer Buffer being converted
 */
function convertFloat32ToInt16(buffer: Float32Array): ArrayBuffer {
  let l: number = buffer.length;
  let buf: Int16Array = new Int16Array(Math.ceil(l / 3));

  for (let i = 0; i < l; i += 3) {
    buf[i / 3] = Math.max(-1, Math.min(1, buffer[i])) * 32767;
  }

  return buf.buffer;
}

const handleAudioStream = (stream: MediaStream): void => {
  let audioTracks = stream.getAudioTracks();
  if (audioTracks.length > 0) {
    let audioTrack = audioTracks[0];
    let AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    audioContext = new AudioContext();
    scriptProcessorNode = audioContext.createScriptProcessor(2048, 1, 1); // bufferSize, numInputChannels, numOutputChannels
    mediaStreamSource = audioContext.createMediaStreamSource(
      new MediaStream([audioTrack])
    );
    scriptProcessorNode.connect(audioContext.destination);
    mediaStreamSource.connect(scriptProcessorNode);

    scriptProcessorNode.onaudioprocess = (
      audioProcessingEvent: AudioProcessingEvent
    ) => {
      let inputBuffer = audioProcessingEvent.inputBuffer.getChannelData(0); // Assuming mono audio
      let left16 = convertFloat32ToInt16(inputBuffer);
      if (audioStreamSocket) {
        if (audioStreamSocket.connected) {
          audioStreamSocket.emit("binaryAudioData", left16);
        }
      }
    };
  }
};

const closeAllaudioStreamSocket = () => {
  // Clear the listeners (prevents issue if opening and closing repeatedly)
  audioStreamSocket.emit("endGoogleCloudStream");
  audioStreamSocket.off("speechData");
  audioStreamSocket.off("googleCloudStreamError");
  speechToTextUtils.stopRecording();
  
  audioStreamSocket.disconnect();
};
