import React, { useState,useContext ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { Form, Spinner } from 'react-bootstrap';
import Dashboard from './Dashboard';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  showModal: boolean;
  onHide: () => void;
  setSelectedLanguage:(value: string) => void
  selectedLanguage:string
}

// the type for the languages object
type Languages = {
  [key: string]: string;
};

const DashboardModal: React.FC<ModalProps> = ({ showModal, onHide, setSelectedLanguage,selectedLanguage}) => {
  
  const [token] = useContext(UserContext);
  const [languages, setLanguages] = useState<Languages>({});
  const [loading, setLoading] = useState<boolean>(false); 
  const { t } = useTranslation();
  const fetchTranscriptSummaryLanguageOption = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/softphone/user/languages`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: (token || ""),
        }
      });

      setLanguages(response.data.languages);
      setSelectedLanguage(response.data.user_default_language)
      
      return true;
    } catch (error) {
      console.log("Error fetching transcription data:", error);
    }finally {
      setLoading(false);
    }
  }
  
  const handleLanguageChange = async (changedLanguageCode: string) => {
    setSelectedLanguage(changedLanguageCode);
    try {
      const response = await axios.post(`/api/set/user/default/language`, 
      {
        languageCode: changedLanguageCode
      }, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: (token || ""),
        }
      });
      return true;
    } catch (error) {
      console.log("Error setting default language:", error);
    }
  }

  useEffect(() => {
    if(showModal){
      fetchTranscriptSummaryLanguageOption();
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={onHide} backdrop="static" animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Settings")}</Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        
      <div>
      {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
        <>
           <Form.Label htmlFor="language-select">
           {t("Choose a default language for transcription and summary")} :
           </Form.Label>
           
           <Form.Select
            id="language-select"
            value={selectedLanguage}
            onChange={(e) =>  handleLanguageChange(e.target.value)}
          >
            {Object.entries(languages).map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Form.Select>
        </>
      ) }
    </div>
       
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
        {t("Close")} 
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardModal;
