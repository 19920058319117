import React,{ useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../languageSelector/language_selector";

interface ModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}
const TopNavbar: React.FC<ModalProps>  = ({ showModal, setShowModal}) => {
  const navigate = useNavigate();
  const [token, setToken] = useContext(UserContext);
  const [extension, setExtension] = useState("");
  const handleLogOut = async () => {
    setToken("");
    try {
      const response = await axios.post(
        "/api/softphone/logout",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        }
      );
      
    } catch (error: any) {
      console.error("An unexpected error occurred:", error);
    }

    Cookies.remove('Token');
    navigate("/");
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get("/api/users/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: (token || ""),
          }
          
        });
        const data = response.data; 
        setExtension(data.extension);
      } catch (error) {
        // localStorage.setItem("Token", (token || ""));
      }
    };
    fetchUser();
  },[]);
  const { t, i18n } = useTranslation();
  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    i18n.changeLanguage(language); //change the language
}

  return(
    <div className="top-navbar">
    <img className="logo" src="/static/media/logo.b32309533aa8d75530d96dc2d4dd5643.svg" alt="pbxAI" />
    <div className="dropdown-button">
    <LanguageSelector />
    <Dropdown >
      <div >
          <Dropdown.Toggle className="drop-color"  id="dropdown-basic">
            {extension}
          </Dropdown.Toggle>
          </div>
          <Dropdown.Menu>
            <Dropdown.Item  onClick={() => setShowModal(true)}>{t("Settings")}</Dropdown.Item>
            <Dropdown.Item href="#" onClick={handleLogOut}>{t("Logout")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>
    </div>
  );

};

export default TopNavbar;
