import React from 'react'
import Header from './header';
import './file.css';
import Objects from './images/Objects.png'
import featureImage from './images/featureImage.svg'
import logo from './images/logo.svg'  
import social1 from './images/social1.svg'
import social2 from './images/social2.svg'
import social3 from './images/social3.svg'
import social4 from './images/social4.svg'
import social5 from './images/social5.svg'
import social6 from './images/social6.svg'
import feat1 from './images/feat1.svg'
import feat2 from './images/feat2.svg'
import feat3 from './images/feat3.svg'
import feat4 from './images/feat4.svg'
import waves from './images/Waves.svg'


interface FeatureProps {
  icon: string;
  title: string;
  description: string;
}

interface PlanProps {
  planType: string;
  price: string;
  features: string[];
}
const FeatureCard: React.FC<FeatureProps> = ({ title, description, icon }) => (
  <div className="feature-card">
    <img src={icon} alt="Vector" className="feature-icon pb-2" />
    <h4 className='pb-2'>{title}</h4>
    <p>{description}</p>
  </div>
);

// PlanCard component
const PlanCard: React.FC<PlanProps> = ({ planType, price, features }) => (
  <div className="plan-card">
    <h4 className=''>{planType}</h4>
    <p>{price}</p>
    <div className="p-2">
      {features?.map((feature, index) => (
          <div className="d-flex gap-3" key={index}>
            <span className="tick-icon">&#10003;</span> 
            <div className="flex-grow-1">{feature}</div>
          </div>
        ))}
        </div>
  </div>
);
interface SocialIconProps {
  src: string;
  alt: string;
  url: string;
}

  const SocialIcon: React.FC<SocialIconProps> = ({ src, alt, url }) => {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img
          loading="lazy"
          src={src}
          alt={alt}
          className="socialIcons rounded-circle shadow-lg "
        />
      </a>
    );
  };

const features = [
  {
    icon: feat1,
    title: "Seamless Integration",
    description:
      "Connect Pbxai with your FreePBX system effortlessly, without the need for complex configurations.",
  },
  {
    icon: feat2,
    title: "Empowered Agents",
    description:
      "Equip your call agents with advanced tools, including AI-driven insights and real-time transcription, to streamline call handling and boost productivity.",
  },
  {
    icon: feat3,
    title: "Actionable Insights",
    description:
      "Gain valuable customer insights at a glance with our AI Call Summary feature, enabling personalized and effective interactions.",
  },
  {
    icon: feat4,
    title: "Flexible Plans",
    description:
      "Choose from our flexible subscription plans tailored to fit businesses of all sizes, ensuring high-quality customer service is accessible to everyone.",
  },
];

const socialIcons = [
  { src: social1, alt: "Social icon 1", url: "https://www.facebook.com" },
  { src: social2, alt: "Social icon 2", url: "https://www.twitter.com" },
  { src: social3, alt: "Social icon 3", url: "https://www.linkedin.com" },
  { src: social4, alt: "Social icon 4", url: "https://www.instagram.com" },
  { src: social5, alt: "Social icon 5", url: "https://www.youtube.com" },
  { src: social6, alt: "Social icon 6", url: "https://www.pinterest.com" }

];
const Homepage = () => (
  <div className='homepage'>
    <Header />
    <div className="container-fluid homepage col-12 col-md-auto">
      <div className='headerTagline'>
      <div className="tagline">
        <h1 className='pb-2'>Supercharge your PBX with PBXAI</h1>
        <div className="content">
          <p className='text-white pb-4 '>Integrate AI seamlessly into your CTI for enhanced functionality and performance</p>
          {/* <button className="register-button">REGISTER</button> */}
        </div>    
      </div>
      <div className="taglineImage">
        <img src={Objects} alt="Objects" className='img-fluid' />
        </div>
      </div>



      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column image-column">
              <img
                src={featureImage}
                alt="Revolutionize Your Call Center with AI"
                className="hero-image"
              />
            </div>
            <div className="column content-column p-lg-5 ">
              <div className="content-wrapper">
                <h2 className="title">Revolutionize Your Call Center with AI</h2>
                <p className="description">
                  At Pbxai, we're dedicated to enhancing your FreePBX system with cutting-edge AI
                  technology. Say goodbye to outdated call management and hello to a new era of
                  efficiency and productivity.
                </p>
                <div className="features">
                  <div className="feature-row">
                    {features.slice(0, 2).map((feature, index) => (
                      <FeatureCard key={index} {...feature} />
                    ))}
                  </div>
                  <div className="feature-row">
                    {features.slice(2, 4).map((feature, index) => (
                      <FeatureCard key={index} {...feature} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section className="plans">
        <h3 className='text-center '>Our Plans</h3>
        <div className="plans-grid text-start ">
          <PlanCard planType="Basic Plan" price="Free" features={['Browser-based call manager', 'KIOSK Manager']} />
          <PlanCard planType="Standard Plan" price="Coming Soon" features={['Browser-based Advanced call manager', 'AI Call summary', 'AI assistant', 'Real-time transcription']} />
          <PlanCard planType="Premium Plan" price="Coming Soon" features={['All Basic and Standard features', 'Built-in CRM for comprehensive customer management']} />
        </div>
      </section>


      <div className="d-flex flex-column align-items-center pt-20 bg-white">
        <div className="mt-24 text-sm font-semibold text-center text-black w-100 max-md:mt-10 max-md:max-w-full">
          <img src={logo} alt="pbxAI" className="w-20 p-4" />
          <h5>Transform Your Call Center Today</h5>
          {/* <p className='p-2'>
            Ready to take your FreePBX system to the next level? Join Pbxai and revolutionize your call center experience. Sign up for a free trial or contact us for more information.
          </p> */}
        </div>
        {/* <button className="justify-content-center align-items-center px-4 py-1.5 mt-9 max-w-full text-base text-center text-white bg-primary rounded-pill border-0  max-md:px-3">
          Contact Us
        </button>
        <div className="socialIcons pt-4 ">
          {socialIcons.map((icon, index) => (
            <SocialIcon key={index} src={icon.src} alt={icon.alt} url={icon.url} />
          ))}
        </div> */}
        <img
          loading="lazy"
          src={waves}
          alt="Footer"
          className="align-self-stretch mt-20 w-100 aspect-ratio-12/5"
        />
      </div>

    </div>
  </div>
);


export default Homepage