import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGear} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import DashboardModal from '../dashboard/DashboardModal';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import { Stack, styled, Typography } from '@mui/material';

interface TranscribeSectionProps {
    transcribedData: string[],
    selectedLanguage: string,
    setSelectedLanguage: (value: string) => void
    transcriptionToggleVisibility : boolean
    transcriptionToggle: boolean,
    setTranscriptionToggle: (value: boolean) => void,
    callStatus: string
}
const TranscribeSection: React.FC<TranscribeSectionProps> = ({
    transcribedData,
    selectedLanguage,
    setSelectedLanguage,
    transcriptionToggleVisibility,
    transcriptionToggle,
    setTranscriptionToggle,
    callStatus
   
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const supportedLanguages: Record<string, string> = {
        'en-US': 'English(US)',
        'ja-JP': 'Japanese',

      };
      const textareaRef =  useRef<HTMLTextAreaElement>(null);
      useEffect(() => {
        if (textareaRef.current) {
          textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
      }, [transcribedData]);

      const handleTranscriptionToggleChange = () => {

        setTranscriptionToggle(!transcriptionToggle)
      }

      

  const { t } = useTranslation();
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  return (
    <div>
      <div className='transcribe-heading'>
      <h4>{t("TRANSCRIPTION")}</h4>
          
      <p>{t("Chosen Language")} : {supportedLanguages[selectedLanguage]}  <FontAwesomeIcon
            className="settings-transcription-icon"
            icon={faGear}
            style={{ color: "grey", cursor: "pointer" , fontSize: "15px" }}
            onClick={() => setShowModal(true)}
        /></p>
       {callStatus === "Call confirmed" &&
        <Stack direction="row" spacing={1} alignItems="center"  style={{paddingTop:"10px"}}>
              <Typography>{t("Off")}</Typography>
              <AntSwitch disabled={!transcriptionToggleVisibility}  checked={transcriptionToggle}
                onChange={handleTranscriptionToggleChange} inputProps={{ 'aria-label': 'ant design' }} />
              <Typography>{t("On")}</Typography>
        </Stack>
        }
     </div>
    <div className='transcribe-section'>
        <Form.Control
        as="textarea"
        id='transcribedData'
        name='transcribedData'
        rows={3} // You can adjust the number of rows as needed
        style={{overflowY: 'auto' }} // Adjust width and height as needed
        value={transcribedData}
        readOnly
        ref={textareaRef}
      />
    </div>
    <DashboardModal showModal={showModal} onHide={() => setShowModal(false)} setSelectedLanguage={setSelectedLanguage}  selectedLanguage={selectedLanguage}/>
    </div>
  )
}

export default TranscribeSection
