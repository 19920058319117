import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";
// import { changeLanguage } from "i18next";


const languages = [
    {code : "en", name : "English"},
    {code : "ja", name : "Japanese"},
];
const changeLanguage = (lng: any) =>{
    i18n.changeLanguage(lng);
}

const LanguageSelector = () => {
  const {i18n} = useTranslation();
    return(
        <div>
           
                    <Dropdown >
                    <div >
                        <Dropdown.Toggle className="drop-color"  id="dropdown-basic">
                        <FontAwesomeIcon icon={faLanguage} style={{color: "white",}} />
                        </Dropdown.Toggle>
                        </div>
                        {/* {languages.map((lng) => {
                return ( */}
                        <Dropdown.Menu>
                          <Dropdown.Item  onClick={ () =>changeLanguage("en")}>English</Dropdown.Item>
                          <Dropdown.Item  onClick={ () =>changeLanguage("ja")}>Japanese</Dropdown.Item>
                        </Dropdown.Menu>
                        {/* )  } )} */}
                      </Dropdown>
                
          
           
        </div>
    )
}
export default LanguageSelector;