import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "./navbar";
import "./kiosk.css";
import axios from "axios";
import JsSIP from "jssip";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

interface Extensions_json {
  [key: string]: {
    extension: string;
    extension_name: string;
  };
}

let session: any;
const Kiosks = () => {
  const navigate = useNavigate();
  const [extensions, setExtensions] = useState<Extensions_json | any>(null);
  const [currentScreen, setCurrentScreen] = useState<"kiosk" | "callingScreen">(
    "kiosk"
  );
  const [token, setToken] = useContext(UserContext);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioRef2 = useRef<HTMLAudioElement>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [extensionName, setExtensionName] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [timer, setTimer] = useState(0);

  let ua: any;
  let config: any;

  const dashboard_interface = async () => {
    try {
      await axios.get("/api/user/dashboard", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });

      navigate("/Kiosk");
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          navigate("/Dashboard");
        } else if (
          error.response.data &&
          error.response.data.error_description
        ) {
          setErrorMessage(error.response.data.error_description);
        } else {
          setErrorMessage("Login failed.");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    // let token = Cookies.get('Token');

    if (callStatus === "Call confirmed") {
      // Start the timer
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      // Initialize intervalId to avoid the TypeScript error
      intervalId = setInterval(() => {});
      // Clear the timer when the call status changes
      clearInterval(intervalId);
      setTimer(0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [callStatus]);

  useEffect(() => {
    kiosk_api_data();
  }, []);

  useEffect(() => {
    if (token == "") {
      navigate("/");
    }

    dashboard_interface();
  }, [token]);

  const kiosk_api_data = async () => {
    try {
      const response = await axios.get("/api/user/dashboard", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      let data: any = response.data;
      setExtensions(data.extensions);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage("Resource not found.");
        } else if (
          error.response.data &&
          error.response.data.error_description
        ) {
          setErrorMessage(error.response.data.error_description);
        } else {
          navigate("/");
          setErrorMessage("Login failed.");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  const hangupCall = () => {
    session.terminate();
  };
  const webrtc_api = async (extensionValue: string) => {
    try {
      const response = await axios.get("/api/user/config", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      const data = response.data;

      config = {
        sockets: [
          new JsSIP.WebSocketInterface(
            "wss://" + data.hostname + ":" + data.ws_port + "/ws"
          ),
        ],
        uri: "sip:" + data.webrtc_extension + "@" + data.hostname,
        password: data.webrtc_extension_password,
        realm: data.hostname,
        connection_recovery_max_interval: 60,
        connection_recovery_min_interval: 4,
        contact_uri: "sip:" + data.webrtc_extension + "@" + data.hostname,
        no_answer_timeout: 30,
        session_timers: true,
        session_timers_force_refresher: false,
        session_timers_refresh_method: "update",
        register: true,
        register_expires: 172800,
        registrar_server: "sip:" + data.hostname,
      };

      ua = new JsSIP.UA(config);
      // Call the extension
      // Make a call

      const eventHandlers = {
        progress: function (e: any) {
          const audio: any = audioRef2.current;
          const audioFilePath = "../../assets/call/macroform-cold_day.wav"; // Define the path here
          audio.src = audioFilePath; // Set the source dynamically
          audio.loop = true; // Set loop to true for continuous playback
          audio.play();
          setCallStatus("Call in progress");
          console.log("call is in progress");
        },
        failed: function (e: any) {
          setCurrentScreen("kiosk");
          console.log("call failed with cause: " + e);
        },
        ended: function (e: any) {
          setCallStatus("Call ended");
          setCurrentScreen("kiosk");
          console.log("call ended with cause: " + e);
        },
        confirmed: function (e: any) {
          setCallStatus("Call confirmed");
          const audio: any = audioRef2.current;
          if (audio) {
            audio.pause(); // Pause the audio if it's playing
          }
          console.log("call confirmed");
        },
      };

      const options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: false },
        pcConfig: {
          iceServers: [
            { urls: "stun:" + data.stun_address },
            {
              urls: "turn:" + data.turn_address,
              username: data.turn_username,
              credential: data.turn_password,
            },
          ],
        },
        iceTransportPolicy: "all",
        rtcpMuxPolicy: "negotiate",
        rtcOfferConstraints: { offerToReceiveAudio: true },
        mandatory: {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: false,
          DtlsSrtpKeyAgreement: true,
        },
      };

      ua.start();
      session = ua.call("sip:" + extensionValue + "@" + data.hostname, options);
      let myCandidateTimeout: any = null;
      // Important fix for removing delay in connecting inbound/outbound calls
      session.on("icecandidate", function (candidate: any) {
        console.log("getting a candidate" + candidate);
        if (myCandidateTimeout != null) clearTimeout(myCandidateTimeout);
        // 1 second timeout after the last icecandidate received!
        myCandidateTimeout = setTimeout(candidate.ready, 1000);
      });
      session.connection.addEventListener("addstream", (event: any) => {
        const audioElement = audioRef.current;
        if (audioElement) {
          audioElement.srcObject = event.stream;
          audioElement.play();
        }
      });

      setCurrentScreen("callingScreen");
    } catch (error) {
      console.error(error);
    }
  };
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;

    return formattedTime;
  };

  return (
    <div id="kiosk">
      <Navbar />
      {currentScreen === "kiosk" && (
        <div className={`card-container`}>
          {extensions &&
            Object.keys(extensions).map((key: string) => {
              return (
                <button
                  key={key}
                  onClick={() => {
                    webrtc_api(extensions[key].extension);
                    setExtensionName(extensions[key].extension_name);
                  }}
                  style={{ border: "none", background: "none", padding: "0" }}
                >
                  <div className={`card`}>
                    <div className="number">
                      {extensions[key].extension_name}
                    </div>
                  </div>
                </button>
              );
            })}
        </div>
      )}
      {currentScreen === "callingScreen" && (
        <div className="calling-screen-container">
          <h4>{extensionName}</h4>

          <div className="profile-picture-container">
            <img
              className="img-profile-circle"
              src="./assets/user-placeholder.jpg"
              alt="user"
            />
            <p className="timer-kiosk">
              {callStatus === "Call confirmed" ? formatTime(timer) : ""}
            </p>
          </div>

          <button
            type="button"
            className="hangup btn btn-danger button-wid"
            onClick={() => {
              hangupCall();
            }}
          >
            End Call
          </button>

          <audio ref={audioRef} id="audio-element"></audio>
          <audio ref={audioRef2} id="audio-element2"></audio>
        </div>
      )}
    </div>
  );
};

export default Kiosks;
