import React, { useState, useEffect, useContext } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "./contacts.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from '@mui/icons-material/Phone';
import DescriptionIcon  from '@mui/icons-material/Description';
import CustomModal from "./modalbox";
import ViewModal from "./Viewmodal";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type StateOption = 'history' | 'notes';
interface ContactsItem {
  id: number;
  name: string;
  email: string;
  phone: string;
  title: string;
  company: string;
  notes: string;
}
interface ContactsTableProps{
  contactAdded:any;
  onCallContact: (phoneNumber: string) => void;
}

interface ContactsTable {
  data: ContactsItem[];
  fetchData: () => Promise<void>;
}

const ContactsTable: React.FC<ContactsTableProps> = ({contactAdded, onCallContact}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 10;
  const [notes, setNotes] = useState<string>('');
  const [data, setData] = useState<ContactsItem[]>([]);
  const [token, setToken] = useContext(UserContext);
  const [viewModalState, setViewModalState] = useState<StateOption>('history');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editContact, setEditContact] = useState<ContactsItem | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [contactNumber, setContactNumber] = useState<string>('');
  const handleDeleteModalOpen = (id: any) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedId(null);
  };
  const handleEdit = (contact: ContactsItem) => {
    setIsEditing(true);
    setEditContact(contact);
    setShowModal(true);
  };
  const handleNotes=(notes:string)=>{
    setNotes(notes);
    setViewModalState('notes');
    setShowViewModal(true);
  }
  const handleAdd = () => {
    setIsEditing(false);
    setShowModal(true);
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil((filteredData?.length ?? 0) / itemsPerPage);
  const currentItems = filteredData?.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/user/contacts/view", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      setData(JSON.parse(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [contactAdded]);

  const handleDelete = async (id: any) => {
    try {
      const response = await axios.post(
        `/api/user/contacts/delete/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        }
      );
      fetchData();
    } catch (error) {
      console.error(error);
    }
    handleDeleteModalClose();
  };
  const handleView = (number:string) => {
    setContactNumber(number);
    setViewModalState('history')
    setShowViewModal(true);
    
  };
  
  return (
    <>
      
      {loading ? (
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <div className="search-container">
            <button
              className="addcntct btn btn-primary"
              onClick={() => handleAdd()}
            >
              {t("Add Contact")}
            </button>
            <div className="search">
              <input
                type="text"
                placeholder={t("Search By")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="container-fluid table-responsive">
            <Table bordered hover>
              <thead>
                <tr>
                  <th className="table-head">{t("Name")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Phone")}</th>
                  <th>{t("Title")}</th>
                  <th>{t("Company")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((item, index) => (
                  <tr key={index}>
                    <Tooltip title={item.name}>
                      <td className="truncate">{item.name}</td>
                    </Tooltip>
                    <Tooltip title={item.email}>
                      <td className="truncate">
                        {item.email ? item.email : "-"}
                        <br />{" "}
                      </td>
                    </Tooltip>
                    <Tooltip title={item.phone}>
                      <td className="truncate">{item.phone}</td>
                    </Tooltip>
                    <Tooltip title={item.title}>
                      <td className="truncate">
                        {item.title ? item.title : "-"}
                      </td>
                    </Tooltip>
                    <Tooltip title={item.company}>
                      <td className="truncate">
                        {item.company ? item.company : "-"}
                      </td>
                    </Tooltip>
                    <td>
                      <div className="contacts-icons">
                      <Tooltip title={t("Call")}>
                          <PhoneIcon
                            className="contact-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => onCallContact && onCallContact(item.phone)}
                          />
                        </Tooltip>
                        <Tooltip title={t("Call History")}>
                          <HistoryIcon
                            className="contact-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleView(item.phone)}
                          />
                        </Tooltip>
                        <Tooltip title={t("Edit")}>
                          <EditIcon
                            className="contact-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(item)}
                          />
                        </Tooltip>
                        <Tooltip title={t("View Notes")}>
                          <DescriptionIcon 
                            className="contact-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleNotes(item.notes ? item.notes : "")}
                          />
                        </Tooltip>
                        <Tooltip title={t("Delete")}>
                          <DeleteIcon
                            className="contact-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteModalOpen(item.id)}
                          />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={pageCount === 1 ? "d-none" : "pagination"}
            activeClassName={"active"}
            previousClassName={currentPage === 0 ? "d-none" : "page-item"}
            nextClassName={
              currentPage === pageCount - 1 ? "d-none" : "page-item"
            }
          />
          <CustomModal
            show={showModal}
            onHide={() => setShowModal(false)}
            setData={setData}
            isEditing={isEditing}
            editContact={editContact}
            fetchData={fetchData}
          />
              <ViewModal
            show={showViewModal}
            onHide={() => setShowViewModal(false)}
            setData={setData}
            isEditing={isEditing}
            fetchData={fetchData}
            contactNumber={contactNumber}
            viewModalState={viewModalState}
            notes={notes}
          />

          <Modal
          centered
          dialogClassName="modal-dialog-centered"
          show={showDeleteModal} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Contact")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this contact?")}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteModalClose}>
                {t("Cancel")}
              </Button>
              <Button
                variant="danger"
                onClick={() => handleDelete(selectedId!)}
              >
                {t("Delete")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ContactsTable;
